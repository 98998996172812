export const COLOR = {
  mainColor: "#d11919",
  subColor:
    "linear-gradient( 95deg,#d11717 0%,rgb(233,64,87) 50%,#d11717 100%)",
  green: "#58DA12",
  buttonColor: "#12A2DA",
  buttonTextColor: "white",
  priceColor: "#40a7e3",
  mixColor:
    "linear-gradient( 95deg,#d11717 0%,rgb(233,64,87) 50%,#d11717 100%)",
  mixp: "linear-gradient( 95deg,rgb(138,35,135) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
  mixr: "linear-gradient( 95deg,rgb(233,64,87) 0%,rgb(138,35,135) 50%,rgb(233,64,87) 100%)",
  select: "#d95252",
  formButton: "#3495eb",
  ashColor: "#dae2e8",
  orderProcessing: "#ff3b3b",
  orderConformed: "#ffec17",
  orderSend: "#17bdff",
  orderDelivered: "#83f07f",
};
