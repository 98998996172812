import React, { useEffect, useState } from "react";
import AboutUs from "../Commen/AboutUsShort/AboutUsShort";
import Card from "@material-ui/core/Card";
import Image from "./Components/image";
import Details from "./Components/Details";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import RelatedItem from "views/CustomerView/Commen/RelatedItem/RelatedItem";
import { getItem } from "service/GettemRequest/GettemRequest";
import LoadingItemFullView from "./LoadingItemFullView";
import { relatedItems } from "service/RelatedItemsRequest/RelatedItemsRequest";
import { CATEGORIES } from "data/data";

const ItemFullView = (props) => {
  const id = props.match.params.id;

  const [item, setItem] = useState(null);
  const [_relatedItems, setRelatedItems] = useState(null);

  const getMainCategory = (item) => {
    try {
      const category = CATEGORIES.filter((c) => c.id === item.mainCategory);
      const subCategory = category[0].sub.filter(
        (s) => s.id === item.subCategory
      );
      return { main: category[0].name, sub: subCategory[0].name };
    } catch (error) {}
  };
  useEffect(() => {
    const GetItem = async () => {
      const res = await getItem(id);
      if (res.data) {
        setItem(res.data);
      }
    };
    const GetRelatedItems = async () => {
      const res = await relatedItems(id);
      if (res) {
        setRelatedItems(res);
      }
    };
    GetItem();
    GetRelatedItems();
  }, [props.match.params, id]);

  const handleOnClick = (route) => {
    props.history.push(route);
  };

  return (
    <Grid
      sm={10}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      {item ? (
        <div>
          <Grid
            style={{
              marginLeft: 20,
              marginTop: 20,
              background: "white",
              maxWidth: 910,
            }}
          >
            <div style={{ padding: 10 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <h6
                  style={{
                    cursor: "pointer",
                    borderBottom: "solid",
                    borderWidth: 0.7,
                    borderColor: "blue",
                  }}
                  color="textPrimary"
                  onClick={() => {
                    handleOnClick("/");
                  }}
                >
                  {"Home"}
                </h6>
                <h6
                  style={{
                    cursor: "pointer",
                    borderBottom: "solid",
                    borderWidth: 0.7,
                    borderColor: "blue",
                  }}
                  color="textPrimary"
                  onClick={() => {
                    handleOnClick(
                      `/layout/main_categories/${item.mainCategory}/1`
                    );
                  }}
                >
                  {getMainCategory(item).main}
                </h6>
                <h6
                  style={{
                    cursor: "pointer",
                    borderBottom: "solid",
                    borderWidth: 0.7,
                    borderColor: "blue",
                  }}
                  color="textPrimary"
                  onClick={() => {
                    handleOnClick(
                      `/layout/main_categories/${item.mainCategory}/${item.subCategory}`
                    );
                  }}
                >
                  {getMainCategory(item).sub}
                </h6>
                <h6 color="textPrimary">{item.name}</h6>
              </Breadcrumbs>
            </div>
            <Divider style={{ marginBottom: 15 }} />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <Grid sm={6} xs={12}>
                <Image image={item.image} item={item} />
              </Grid>
              <Details item={item} history={props.history} />
            </Grid>
          </Grid>

          <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
            <RelatedItem history={props.history} items={_relatedItems} />
          </Grid>
          <Grid
            style={{
              marginLeft: 20,
              marginTop: 20,
              marginBottom: 20,
              background: "while",
            }}
          >
            <Card style={{ padding: 30 }}>
              <AboutUs />
            </Card>
          </Grid>
        </div>
      ) : (
        <LoadingItemFullView />
      )}
    </Grid>
  );
};

export default ItemFullView;
