import { ITEMS } from "./../../data/items";
import HTTP from "service/HttpService/HttpService";

export const getSeachResult = (string) => {
  const seachResult = ITEMS.filter((i) => i.id >= 12);

  return seachResult.length > 0 ? seachResult : false;
};

export const GetSeachResult = async (string, page) => {
  const { data } = await HTTP.get(
    `${HTTP.endPoint}search_bar_result/${string}/${page}/4`
  );
  return data.content.length > 0
    ? { data: data.content, total: data.total }
    : false;
};

export const GetSeachResultAdmin = async (string, page) => {
  try {
    const { data } = await HTTP.get(
      `${HTTP.endPoint}search_bar_result/${string}/${page}/4`
    );
    return data;
  } catch (error) {
    alert("connection error");
  }
};
