import React from "react";
import { Grid, Card } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

const ShowCountBox = (props) => {
  return (
    <Grid xs={6} sm={3} style={{ padding: 10 }}>
      <Card
        style={{ padding: 20, background: props.color }}
        onClick={() => {
          props.onClick();
        }}
      >
        <Grid container direction="column" justify="center" alignItems="center">
          <h1 style={{ margin: 0 }}>{props.count}</h1>
          <Divider />
          <p style={{ margin: 0, fontWeight: 600 }}>{props.name}</p>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ShowCountBox;
