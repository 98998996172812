import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Skeleton from "@material-ui/lab/Skeleton";

export default function LoadingDetails() {
  return (
    <div>
      <Skeleton animation={"wave"} height={40} width="100%" />
      <Divider style={{ marginBottom: 15 }} />
      <Skeleton animation={"wave"} height={30} width="25%" />
      <Skeleton animation={"wave"} height={30} width="25%" />
      <Skeleton animation={"wave"} height={50} width="25%" />
      <Skeleton animation={"wave"} height={50} width="50%" />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{ margin: 5, color: "" }}
      >
        <LocalOfferIcon />
        <Skeleton animation={"wave"} height={20} width="40%" />
      </Grid>
      <Skeleton animation={"wave"} height={20} width="50%" />
      <Skeleton animation={"wave"} height={15} width="100%" />
      <Skeleton animation={"wave"} height={15} width="100%" />
      <Skeleton animation={"wave"} height={15} width="100%" />
      <Skeleton animation={"wave"} height={15} width="100%" />
      <Skeleton animation={"wave"} height={15} width="100%" />
    </div>
  );
}
