import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { citys, states } from "data/data";

const SelectionAreas = ({ handleChange, values ,handleSearchButton}) => {
  const dateValidation = () => {
    if (values.to === "" && values.from === "") return false;
    else if (values.to !== "" && values.from !== "") {
      if (values.to < values.from) {
        alert("Invalid data range");
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  return (
    <div style={{ marginTop: 20 }}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        <div class="form-group">
          <label for="exampleFormControlSelect1">State</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            onChange={handleChange("state")}
          >
            <option value={""}>All</option>
            {states.map((c) => (
              <option value={c.name}>{c.name}</option>
            ))}
          </select>
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect1">City</label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            onChange={handleChange("city")}
          >
            <option value={""}>All</option>
            {citys.map((c) => (
              <option value={c.name}>{c.name}</option>
            ))}
          </select>
        </div>

        <TextField
          id="from"
          label="from"
          type="date"
          // defaultValue={today}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange("from")}
        />
        <TextField
          id="to"
          label="to"
          type="date"
          // defaultValue={today}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleChange("to")}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={dateValidation()}
          onClick={() => {
            handleSearchButton();
          }}
        >
          Search
        </Button>
      </Grid>
    </div>
  );
};
export default SelectionAreas;
