import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "../../../../../util/theme";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "white",
  },
  SearchIcon: {
    background: "red",
    marginLeft: 5,
    height: 55,
    width: 55,
    border: "solid",
    borderColor: "#adadad",
    borderWidth: 1,
    borderRadius: "3px",
    color: "white",
  },
  TextField: {
    marginLeft: 10,
    height: 30,
    width: 240,
    color: "white",
  },
  button: {
    background: "red",
    color: "white",
  },
}));

export default useStyles;

export const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: COLOR.buttonColor,
  },
}))(Badge);
