import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { CATEGORIES } from "data/data";
import { COLOR } from "util/theme";
import StyledTreeItem from "./StyledTreeItem";
import {Link} from 'react-router-dom';
const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function Catogeries(props) {
  const classes = useStyles();

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={
        <ArrowDropDownIcon style={{ color: COLOR.buttonColor, fontSize: 30 }} />
      }
      defaultExpandIcon={
        <ArrowRightIcon style={{ color: COLOR.buttonColor, fontSize: 30 }} />
      }
    >
      {CATEGORIES.map((c) => (
            <Link to={`/layout/main_categories/${c.id}/1`}  style={{textDecoration:'none'}}>
        <StyledTreeItem
          nodeId={c.id}
          labelText={c.name}
          onClick={() => {
            // alert("dfdfdff");
            props.history.push(`/layout/main_categories/${c.id}/1`);
          }}
          style={{ background: "white" }}
        >
          {c.sub &&
            c.sub.map((s) => (
              <Link to={`/layout/main_categories/${c.id}/${s.id}`}  style={{textDecoration:'none'}}>
              <StyledTreeItem
                nodeId={`${s.id}${c.id}`}
                labelText={s.name}
                // onClick={() =>
                //   props.history.push(`/layout/main_categories/${c.id}/${s.id}`)
                // }
                style={{
                  background: "white",
                  marginBottom: 5,
                }}
              >
                
              </StyledTreeItem></Link>
            ))}
        </StyledTreeItem></Link>
      ))}
    </TreeView>
  );
}
