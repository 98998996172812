import HTTP from "service/HttpService/HttpService";

export const SaveOrder = async (
  user,
  DELIVERY,
  CART,
  TOTAL,
  state,
  type,
  tax
) => {
  var today = new Date();
  const ORDER = {
    _id: Date.parse(today),
    user: user._id,
    email: user.email,
    username: `${user.fname} ${user.lname}`,
    userObj: user,
    deliveryInfo: DELIVERY,
    cart: CART,
    cartTotal: TOTAL,
    state: state,
    type: type,
    tax: tax,
  };
  console.log("order", user);
  const res = await HTTP.post(`${HTTP.endPoint}orders`, ORDER);
  return res.data ? res.data : false;
};

export const getOrder = async (userId) => {
  try {
    const res = await HTTP.get(`${HTTP.endPoint}orders/user/${userId}`);
    return res.data.length > 0 ? res.data : " no Orders";
  } catch (error) {
    return "connection error";
  }
};

export const getAllOrder = async (state, page) => {
  try {
    const res = await HTTP.get(
      `${HTTP.endPoint}order_search/state/${state}/${page}/5`
    );
    return res.data;
  } catch (error) {
    return "connection error";
  }
};

export const getDashboardDetails = async (state, page) => {
  try {
    const res = await HTTP.get(`${HTTP.endPoint}dash_bord_orders`);
    return res.data;
  } catch (error) {
    return "connection error";
  }
};

export const updateOrderstate = async (orderId, state) => {
  try {
    const res = await HTTP.put(`${HTTP.endPoint}orders/${orderId}`, {
      state: state,
    });
    return res.data;
  } catch (error) {
    return "connection error";
  }
};

export const cancelOrder = async (orderId) => {
  try {
    const res = await HTTP.put(`${HTTP.endPoint}order_cancel/${orderId}`, {
      state: "cancel",
    });
    return res.data;
  } catch (error) {
    return "connection error";
  }
};

export const adminSearch = async (data) => {
  try {
    const res = await HTTP.get(changeURL(data));
    return res.data;
  } catch (error) {
    alert("connection error");
  }
};

const changeURL = (data) => {
  const { state, city, to, from, page, size } = data;
  if (state === "" && city === "" && to === "" && from === "") {
    return `${HTTP.endPoint}order_search/all/${page}/${size}`;
  } else if (state !== "" && city === "" && to === "" && from === "") {
    return `${HTTP.endPoint}order_search/state/${state}/${page}/${size}`;
  } else if (state !== "" && city !== "" && to === "" && from === "") {
    return `${HTTP.endPoint}order_search/state&city/${state}/${city}/${page}/${size}`;
  } else if (state !== "" && city === "" && to !== "" && from !== "") {
    return `${HTTP.endPoint}order_search/state&date/${state}/${from}/${to}/${page}/${size}`;
  } else if (state !== "" && city !== "" && to !== "" && from !== "") {
    return `${HTTP.endPoint}order_search/state&city&date/${state}/${city}/${from}/${to}/${page}/${size}`;
  } else if (state === "" && city !== "" && to === "" && from === "") {
    return `${HTTP.endPoint}order_search/city/${city}/${page}/${size}`;
  } else if (state === "" && city !== "" && to !== "" && from !== "") {
    return `${HTTP.endPoint}order_search/city&date/${city}/${from}/${to}/${page}/${size}`;
  } else if (state === "" && city === "" && to !== "" && from !== "") {
    return `${HTTP.endPoint}order_search/date/${from}/${to}/${page}/${size}`;
  }
};
