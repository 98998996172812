import React from "react";
import GridItem from "components/Grid/GridItem";
import { Card } from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Style";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(styles);

const LoadingOneItem = (props) => {
  const classes = useStyles();
  return (
    <GridItem
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      className={classes.marginLeft}
    >
      <Card className={classes.card}>
        <CardActionArea>
          <Skeleton
            animation="wave"
            variant="rect"
            style={{
              height: 190,
            }}
          />
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Skeleton animation="wave" height={10} width="80%" />
            <Skeleton animation="wave" height={10} width="80%" />
            <Skeleton animation="wave" height={40} width="80%" />
          </Grid>
        </CardActionArea>
      </Card>
    </GridItem>
  );
};

export default LoadingOneItem;
