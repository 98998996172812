import React from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { COLOR } from "util/theme";

const BestSale = () => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Avatar
        style={{
          padding: 20,
          background: "yellow",
          color: "black",
          fontSize: 10,
          fontWeight: 700,
          border: "solid",
          borderWidth: 1,
          borderColor: COLOR.ashColor,
          webkitBoxShadow: "1px 6px 1px #9E9E9E",
          mozBoxShadow: "1px 6px 10px #9E9E9E",
          boxShadow: "1px 6px 10px #9E9E9E",
          
        }}
      >
        best sale
      </Avatar>
      <p style={{ paddingTop: 20, color: COLOR.ashColor }}>Speedy store mart</p>
    </Grid>
  );
};

export default BestSale;
