import React, { useEffect } from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import AccountCircle from "@material-ui/icons/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "service/authService/authService";
import { RemoveUser } from "store/actions/User";
import IconButton from "./Icon_Button";
import { useStyles } from "./style";
import OrderNotification from "./OrderNotification";
import Menus from "./Menu";
import { LoadData } from "store/actions/Order";

export default function PrimarySearchAppBar(props) {
  const menuId = "primary-search-account-menu";
  const obj = useSelector((status) => status.orderReducer.COUNT);
  const dispatch = useDispatch();

  var A = Date.now();

  useEffect(() => {
    const GetResult = async () => {
     await dispatch(LoadData());
    };
    GetResult();
  }, [A, dispatch]);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuOnClick = () => {
    logout();
    dispatch(RemoveUser());
    props.history.push("/");
  };

  const iconOnClick = () => {
    props.history.push("/admin/finish_order");
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ background: "#424242" }}>
        <Toolbar>
          <OrderNotification
            obj={obj}
            classes={classes.sectionDesktop}
            onClick={iconOnClick}
          />
          <OrderNotification
            obj={obj}
            classes={classes.sectionMobile}
            onClick={iconOnClick}
          />
          <div className={classes.grow} />
          <IconButton
            classes={classes.sectionDesktop}
            menuId={menuId}
            handleProfileMenuOpen={handleProfileMenuOpen}
          >
            <AccountCircle style={{ color: "white" }} />
          </IconButton>
          <IconButton
            classes={classes.sectionMobile}
            menuId={menuId}
            handleProfileMenuOpen={handleProfileMenuOpen}
          >
            <AccountCircle style={{ color: "white" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      {
        <Menus
          anchorEl={anchorEl}
          menuId={menuId}
          isMenuOpen={isMenuOpen}
          handleMenuClose={handleMenuClose}
          onClick={menuOnClick}
        />
      }
    </div>
  );
}
