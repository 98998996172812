import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser } from "../service/authService/authService";
import { useSelector } from "react-redux";

const PrivateRouteSuper = ({ path, component: Component, render, ...rest }) => {
  const USER = useSelector((state) => state.userReducer.USER);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (USER && getCurrentUser().isAdmin === "super") {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return (
            <Redirect
              to={{
                pathname: "/admin/all_summery",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRouteSuper;
// .isAdmin === "admin"
