import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Errors from "../commen/Errors";
import Errors2 from "../commen/Errors2";

import { citys } from "data/data";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { COLOR } from "util/theme";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import OrderTypeselection from "./OrderTypeSelection";

export default function FormeForm({
  handleCityChange,
  handleSubmit,
  errors,
  values,
  isSubmitting,
  handleChange,
  value,
  ordertype,
  handleTypeChange,
}) {
  return (
    <form noValidate onSubmit={handleSubmit}>
      <div
        style={{
          border: "solid",
          borderColor: "blue",
          borderWidth: 0.5,
          borderRadius: 5,
          padding: 50,
        }}
      >
        <h4 style={{ textAlign: "center", marginBottom: 50 }}>
          Delivery Details
        </h4>
        <OrderTypeselection
          handleChange={handleTypeChange}
          values={ordertype}
        />
        <div className="form-row">
          <div className="col-md-12">
            <label for="validationCustom01">Recipient's Name</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("name")}
              value={values.name}
            />
            {errors.name ? <Errors msg={errors.name} /> : null}
          </div>
        </div>

        <div className="form-row mt-3">
          <div className="col-md-12">
            <label for="validationCustom01">Recipient's Phone Number</label>
            <input
              type="text"
              className="form-control"
              onChange={handleChange("phoneNumber")}
              value={values.phoneNumber}
            />
            {errors.phoneNumber ? <Errors msg={errors.phoneNumber} /> : null}
          </div>
        </div>

        <div className="form-row mt-3">
          <div className="col-md-12">
            <label>Delivery Address</label>
            <textarea
              type="text"
              className="form-control"
              onChange={handleChange("address")}
              value={values.address}
            />
            {errors.address ? <Errors msg={errors.address} /> : null}
          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col-md-6">
            <label>Delivery Country</label>
            <select className="custom-select" disabled>
              <option selected disabled value="">
                Sri lanka
              </option>
              <option>...</option>
            </select>
            <div className="valid-feedback">Looks good!</div>
          </div>
          <div className="col-md-6">
            <label>Delivery City</label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              onChange={handleCityChange("city")}
            >
              <option value={""}>Select City</option>
              {citys.map((c) => (
                <option value={c.id}>{c.name}</option>
              ))}
            </select>
            {!value.city ? <Errors2 msg={"plz select the city"} /> : null}
          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col-md-12">
            <label>Special note (optional)</label>
            <textarea
              type="text"
              className="form-control"
              placeholder={
                "Please write your special instructions short and clear."
              }
              onChange={handleChange("note")}
              value={values.note}
            />
            {errors.note ? <Errors msg={errors.note} /> : null}
          </div>
        </div>
        {ordertype === "gift" && (
          <div className="form-row mt-3">
            <div className="col-md-12">
              <label>Sender name</label>
              <input
                type="text"
                className="form-control"
                placeholder={"sender's name"}
                onChange={handleChange("sendName")}
                value={values.sendName}
              />
              {errors.sendName ? <Errors msg={errors.sendName} /> : null}
            </div>
          </div>
        )}
        <RadioGroup
          aria-label="quiz"
          name="quiz"
          value={values.paymentMethod}
          onChange={handleChange("paymentMethod")}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <FormControlLabel
              value="cashOnDelivery"
              control={<Radio />}
              label={
                <div
                  className="row"
                  style={{
                    paddingLeft: 10,
                    paddingTop: 15,
                    marginRight: 5,
                  }}
                >
                  <p>Cash on Delivery</p>
                  <LocalAtmIcon />
                </div>
              }
            />
            <FormControlLabel
              value="cardPayment"
              control={<Radio />}
              label={
                <div
                  className="row"
                  style={{
                    paddingLeft: 10,
                    paddingTop: 15,
                    marginRight: 5,
                  }}
                >
                  <p>Card payment</p>
                  <CreditCardIcon />
                </div>
              }
            />
            <br />
            {errors.paymentMethod ? (
              <div style={{ marginTop: 15 }}>
                {" "}
                <Errors2 msg={errors.paymentMethod} />
              </div>
            ) : null}
          </Grid>
        </RadioGroup>
        <Grid container direction="row" justify="center" alignItems="center">
          {isSubmitting ? (
            <p>Loading..</p>
          ) : (
            <Button
              disabled={value.city === "" ? true : false}
              type={"submit"}
              variant="contained"
              style={{ background: COLOR.formButton, color: "white" }}
            >
              NEXT
            </Button>
          )}
        </Grid>
      </div>
    </form>
  );
}
