import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Style_table_area";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Table from "./Table";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { COLOR } from "util/theme";
import MobileDropMenu from "Layout/MainLayout/Components/NaviBar/common/MobileDropMenu/MobileDropMenu";
const useStyles = makeStyles(styles);

const TableArea = (props) => {
  const cart = useSelector((state) => state.cartReducer.CART);
  const total = useSelector((state) => state.cartReducer.TOTAL);
  const user = useSelector((state) => state.userReducer.USER);
  console.log(" state.userReducer  TableArea  ", user);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMobileClose = () => {
    setAnchorEl(null);
  };

  // const handleMobileOpen = (event) => {
  //   if (user !== null) {
  //     props.history.push("/layout_/cart_checkout");
  //   } else {
  //     setAnchorEl(event.currentTarget);
  //   }
  // };

  const classes = useStyles();
  // const matches = useMediaQuery("(min-width:790px)");
  return (
    <div>
      <div style={{ padding: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Home</Typography>
          <Typography color="textPrimary">View Cart</Typography>
        </Breadcrumbs>
      </div>
      <Divider />

      <Table cart={cart} total={total.toLocaleString()} />

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          style={{ background: COLOR.green, color: "white" }}
          className={classes.button}
          startIcon={<ArrowBackIosIcon />}
        >
          <Link to={"/"} style={{ textDecoration: "none", color: "white" }}>
            KEEP SHOPPING
          </Link>
        </Button>
        <Button
          disabled={cart.length > 0 ? false : true}
          variant="contained"
          style={{ background: COLOR.buttonColor, color: "white" }}
          className={classes.button}
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => {
            if (total >= 1500) {
              if (user) {
                props.history.push("/layout_/cart_checkout");
              } else {
                props.history.push("/layout_/login/cart");
              }
            } else {
              alert("Minimum Order Amount is 1,500/= ");
            }
          }}
        >
          CHECKOUT
        </Button>
      </Grid>
      {
        <MobileDropMenu
          anchorEl={anchorEl}
          handleMobileClose={handleMobileClose}
        >
          {/* <User /> */}
        </MobileDropMenu>
      }
    </div>
  );
};

export default TableArea;
