import React, { useState, useEffect } from "react";
import { viewAllLog } from "service/ViewLogRequest/ViewLogRequest";
import Log from "./Log";

const ViewLog = () => {
  const [ setLoading] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    const GetResult = async () => {
      setData(false);
      setLoading(true);
      const data = await viewAllLog();
      if (data) {
        setLoading(false);
        setData(data);
        // console.log("user", data);
      }
    };
    GetResult();
  }, [setLoading]);

  return (
    <div style={{ padding: 50 }}>
      {data && data.map((l) => <Log log={l} />)}
    </div>
  );
};

export default ViewLog;
