import React from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../style";

const Suggestions = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={3}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <h5 className={classes.Topic}>Suggestions</h5>
      <Grid container direction="row" justify="center" alignItems="center">
        <a className={classes.link} href={"/layout/sub_menu/new_added_items"}>
          New Added Items
        </a>
        <a className={classes.link} href={"/layout/sub_menu/best_selling"}>
          Best selling
        </a>

        <a className={classes.link} href={"/layout/sub_menu/promotion"}>
          Promotion
        </a>
        <a className={classes.link} href={"/layout/sub_menu/on_sale"}>
          On sale
        </a>
      </Grid>
    </Grid>
  );
};

export default Suggestions;
