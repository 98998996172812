import HTTP from "service/HttpService/HttpService";

export const BestSellItems = async () => {
  const res = await HTTP.get(`${HTTP.endPoint}best_selling_items`);

  return res;
};

export const NewAddedItems = async () => {
  const res = await HTTP.get(`${HTTP.endPoint}new_added_items`);
  return res;
};
