import HTTP from "service/HttpService/HttpService";

export const getDashboardOrderDetails = async (state, page) => {
  try {
    const res = await HTTP.get(`${HTTP.endPoint}dash_bord_orders`);
    return res.data;
  } catch (error) {
    return "connection error";
  }
};

export const getDashboardProductDetails = async (state, page) => {
  try {
    const res = await HTTP.get(`${HTTP.endPoint}dashbord_products`);
    return res.data ? res.data : "no data";
  } catch (error) {
    return "connection error";
  }
};
