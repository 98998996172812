import React from "react";
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const SeachArea = ({ values, handleSearch, handleChange, loading }) => {
  return (
    <React.Fragment>
      <div className="form-group row">
        <label for="name" className="col-sm-2 col-form-label">
          Id
        </label>
        <div className="col-sm-8">
          <input
            type="text"
            className="form-control"
            id="id"
            onChange={handleChange("id")}
            value={values.id}
            required
            disabled={loading}
          />
        </div>
        {!loading ? (
          <button
            className="btn btn-primary"
            disabled={values.id === "" ? true : false}
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </button>
        ) : (
            <BarLoader
            css={override}
            width={100}
            color={"#123abc"}
            loading={loading}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default SeachArea;
