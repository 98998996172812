import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { CATEGORIES } from "../../../data/data";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
});

export default function SiteMap() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridContainer>
        {CATEGORIES.map((c) => (
          <Grid xs={12} sm={12} md={12}>
            <Card style={{ margin: 20 }}>
              <Link
                to={`/layout/main_categories/${c.id}/1`}
                style={{ color: "black" }}
              >
                <p style={{ margin: 10, fontWeight: 700 }}>{c.name}</p>
              </Link>
              {c.sub.map((s) => (
                <Link
                  to={`/layout/main_categories/${c.id}/${s.id}`}
                  style={{ color: "black" }}
                >
                  <li style={{ padding: 5 }}>{s.name}</li>
                </Link>
              ))}
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
}
