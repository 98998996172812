import React from "react";
import GridContainer from "components/Grid/GridContainer";
import { Card } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Pagination from "@material-ui/lab/Pagination";
import OneItem from "views/CustomerView/Commen/OneItem/OneItem";
import LoadingOneItem from "views/CustomerView/Commen/OneItem/LoadingOneItem";

const HomePage = (props) => {
  const categoryWiseItem = props.items;
  const handleOnClick = (route) => {
    props.history.push(route);
  };
  return (
    <Card>
      <div style={{ padding: 10 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography
            style={{
              cursor: "pointer",
              borderBottom: "solid",
              borderWidth: 0.7,
              borderColor: "blue",
            }}
            color="textPrimary"
            onClick={() => {
              handleOnClick("/");
            }}
          >
            {"Home"}
          </Typography>
          <Typography color="textPrimary">{props.tabName}</Typography>
        </Breadcrumbs>
      </div>
      <Divider />

      {categoryWiseItem ? (
        <GridContainer style={{ paddingTop: 20 }}>
          {categoryWiseItem.map((c) => (
            <OneItem xs={6} sm={4} md={3} item={c} history={props.history} />
          ))}
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 15 }}
          >
            {props.totalItems > 1 ? (
              <Pagination
                count={props.totalItems}
                variant="outlined"
                color="secondary"
                page={props.page}
                onChange={props.handleChange}
              />
            ) : null}
          </Grid>
        </GridContainer>
      ) : categoryWiseItem === false ? (
        <h3 style={{ margin: 20 }}>no item in this sub catogori</h3>
      ) : (
        <GridContainer>
          {[1, 2, 3, 4].map((c) => (
            <LoadingOneItem xs={6} sm={4} md={3} />
          ))}
        </GridContainer>
      )}
    </Card>
  );
};

export default HomePage;
