import React from "react";

const UserInfo = ({ order }) => {
  console.log("order//////////////////////////////////", order);
  return (
    <table>
      <tr>
        <th>Order Type</th>
        <td>:{order.type}</td>
      </tr>
      <tr>
        <th>User name</th>
        <td>:{order.username}</td>
      </tr>
      <tr>
        <th>E-mail</th>
        <td>:{order.email}</td>
      </tr>
      <tr>
        <th>User Phone Number</th>
        <td>:{order.userObj.number}</td>
      </tr>
    </table>
  );
};

export default UserInfo;
