import React from "react";
import Button from "@material-ui/core/Button";
import { COLOR } from "../../../../../../util/theme";

const User = ({ u }) => {
  return (
    <div
      style={{ margin: 5, padding: 5, borderBottom: "solid", borderWidth: 0.5 }}
    >
      <div className={"row"}>
        <div className={"col-8"}>
          <table>
            <tr>
              <td>{"Name:"}</td>
              <td>{u.name}</td>
            </tr>
            <tr>
              <td>{"E-mail:"}</td>
              <td>{u.email}</td>
            </tr>
          </table>
        </div>
        <div className={"col-4"}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            // type={"submit"}
            style={{
              background: COLOR.formButton,
              color: "white",
              marginBottom: 5,
            }}
          >
            reset
          </Button>
        </div>
      </div>
    </div>
  );
};

export default User;
