import React from "react";
import {  Grid } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";

import SelectionButton from "./SelectionButton";
import Login from "./Login";
import { COLOR } from "../../../util/theme";
import Register from "./Register";
import Reset from "./Reset";
import { useSelector } from "react-redux";
import { userStyles } from "./commen/style";

export default function User(props) {
  const classes = userStyles();
  const VIEW_STATE = useSelector((state) => state.userReducer.VIEW_STATE);

  return (
    <GridContainer>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid
          xs={12}
          sm={6}
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{ borderRight: "solid", borderColor: COLOR.ashColor }}
        >
          <SelectionButton />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          style={{
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          {VIEW_STATE.register && (
            <Register history={props.history} value={props.match.params.key} />
          )}
          {VIEW_STATE.reset && (
            <Reset history={props.history} value={props.match.params.key} />
          )}
          {VIEW_STATE.login && (
            <Login history={props.history} value={props.match.params.key} />
          )}
        </Grid>
      </Grid>
    </GridContainer>
  );
}
