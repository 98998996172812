import React  from "react";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

const Icon_Badge = ({ children, classes, obj, onClick }) => {
  return (
    <div className={classes}>
      <IconButton
        aria-label="show 17 new notifications"
        color="inherit"
        onClick={() => onClick()}
      >
        <Badge badgeContent={obj} color="secondary">
          {children}
        </Badge>
      </IconButton>
    </div>
  );
};

export default Icon_Badge;
