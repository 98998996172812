import React from "react";

const DeliveryInfo = ({order,date}) => {
  return (
    <table>
      <tr>
        <th>Order Id</th>
        <td>:{order._id}</td>
      </tr>
      <tr>
        <th>Date</th>
        <td>
          :{date}
        </td>
      </tr>
      <tr>
        <th>Name</th>
        <td>:{order.deliveryInfo.name}</td>
      </tr>
      <tr>
        <th>Phone Number</th>
        <td>:{order.deliveryInfo.phoneNumber}</td>
      </tr>
      <tr>
        <th>City</th>
        <td>:{order.deliveryInfo.city}</td>
      </tr>
      <tr>
        <th>Address</th>
        <td>:{order.deliveryInfo.address} </td>
      </tr>
      <tr>
        <th>Payment Method</th>
        <td>:{order.deliveryInfo.paymentMethod}</td>
      </tr>
      <tr>
        <th>note</th>
        <td>:{order.deliveryInfo.note}</td>
      </tr>
    </table>
  );
};

export default DeliveryInfo;
