import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer";
import { brands } from "data/data";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
});

export default function Brand() {
  // const brans=[
  //     {id:1,name:"Bahira"},
  //     {id:2,name:"Diliganz"},
  //     {id:3,name:"Magi"},
  //     {id:4,name:"Mailo"},
  //     {id:5,name:"LG"},
  //     {id:6,name:"samsung"},
  //     {id:7,name:"brand_1"},
  //     {id:8,name:"brand_2"},
  //     {id:9,name:"brand_3"},
  //     {id:10,name:"brand_4"},
  //     {id:11,name:"brand_5"},
  //     {id:12,name:"brand_6"},
  //     {id:13,name:"Bahira"},
  //     {id:14,name:"Diliganz"},
  //     {id:15,name:"Magi"},
  //     {id:16,name:"Mailo"},
  //     {id:17,name:"LG"},
  //     {id:18,name:"samsung"},
  //     {id:19,name:"brand_1"},
  //     {id:20,name:"brand_2"},
  //     {id:21,name:"brand_3"},
  //     {id:22,name:"brand_4"},
  //     {id:24,name:"brand_5"},
  //     {id:25,name:"brand_6"},
  // ]
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridContainer>
        {brands.map((c) => (
          <Grid xs={12} sm={6} md={3}>
            <Card style={{ margin: 20 }}>
              <p style={{ margin: 10, fontWeight: 700, padding: 50 }}>
                {c.name}
              </p>
            </Card>
          </Grid>
        ))}
      </GridContainer>
    </div>
  );
}
