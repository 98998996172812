import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Items from "./SubLayout/Items";
import Details from "./SubLayout/Details";
import { useDispatch } from "react-redux";
import { initializCart } from "store/actions/Cart";
import DashBoard from "Layout/DashBoard/DashBoard";
import Admin_User from "./../DashBoard/Admin_User";

const MainLayout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializCart());
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/layout" component={Items} />
      <Route path="/layout_" component={Details} />
      <Route path="/admin" component={DashBoard} />
      <Route path="/admin_user" component={Admin_User} />
      <Redirect from="/" to="/layout/home" />
    </Switch>
  );
};

export default MainLayout;
