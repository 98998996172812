import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "util/theme";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export const selectionStyles = makeStyles({
  root: {
    width: "100%",
  },
  button: {
    background: COLOR.formButton,
    height: 38,
    width: 210,
    marginBottom: 10,
    fontWeight: 600,
  },
  small: {
    width: 25,
    height: 25,
  },
  grid: {
    padding: 2,
    paddingTop: 30,
    paddingBottom: 20,
  },
});


export const userStyles = makeStyles({
  root: {
    background: "white",
    marginTop: 15,
    border: "solid",
    borderColor: COLOR.ashColor,
  },
});