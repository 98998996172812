import React from "react";

const UserInfo = ({ order,date }) => {
  return (
    <div className={"row"}>
      <div className={"col-6"}>
  <p>{`Order Id:${order._id}`}</p>
      </div>
      <div className={"col-6"}>
      <p style={{textAlign:'right'}}>{`Order Date:${date}`}</p>
      </div>
    </div>
  
  );
};

export default UserInfo;
