
import { makeStyles } from "@material-ui/core/styles";


export const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      "&:hover > $content": {
        backgroundColor: "red",
        color: "white",
        fontWeight: 700,
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: "red",
        color: "white",
        fontWeight: 700,
      },
      // "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      //   backgroundColor: "transparent",
      // },
    },
    content: {
      // color: "black",
      // background: "red",
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
  
      paddingRight: theme.spacing(1),
      marginBottom: 5,
      fontWeight: 600,
      fontSize:8,
      border: "solid",
      borderWidth: 0.5,
      "$expanded > &": {},
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  }));