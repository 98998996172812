import React from "react";
import { Grid } from "@material-ui/core";

const CartInfo = ({ order }) => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <table border={1}>
        <tr>
          <td>Image</td>
          <td>Name</td>
          <td>unit price</td>
          <td>qty</td>
          <td>sun total </td>
        </tr>
        {order.cart.map((c) => (
          <tr>
            <td>
              <img src={c.image} width={50}  alt="images"/>
            </td>
            <td>
              <p>{c.name}</p>
            </td>
            <td>
              <p>{c.price}</p>
            </td>
            <td>
              <p>{c.qty}</p>
            </td>
            <td>
              <p>{c.qty * c.price}</p>
            </td>
          </tr>
        ))}
      </table>
    </Grid>
  );
};

export default CartInfo;
