import React from "react";
import { Grid } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Button from "@material-ui/core/Button";

import { useSelector, useDispatch } from "react-redux";
import { selectionStyles } from "./commen/style";
import { ViewChange } from "store/actions/User";

export default function SelectionButton() {
  const classes = selectionStyles();
  const dispatch = useDispatch();
  const VIEW_STATE = useSelector((state) => state.userReducer.VIEW_STATE);

  return (
    <Grid
      xs={12}
      sm={6}
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.grid}
    >
      {!VIEW_STATE.login && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<PersonAddIcon />}
          onClick={() => {
            dispatch(ViewChange("login"));
          }}
        >
          Login
        </Button>
      )}
      {!VIEW_STATE.register && (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<PersonAddIcon />}
          onClick={() => {
            dispatch(ViewChange("register"));
          }}
        >
          Create Account
        </Button>
      )}
    </Grid>
  );
}
