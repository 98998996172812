import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { NAVI } from "data/data";
import { Link } from "react-router-dom";
import { useStyles } from "./TabBarStyle";
import { COLOR } from "../../../../../util/theme";

export default function TabBar() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="white"
        style={{ alignItems: "center", background: "red" }}
      >
        <Tabs
          style={{ background: COLOR.subColor, color: "white" }}
          value={value}
          onChange={handleChange}
          indicatorColor="white"
          textColor="white"
          variant="scrollable"
          scrollButtons="auto"
        >
          {NAVI.map((n) => (
            <Link
              to={`/layout/sub_menu/${n.keyWord}`}
              style={{ color: "white", fontWeight: 900 }}
            >
              <Tab className={classes.dd} label={n.name} key={n.id} />
            </Link>
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
