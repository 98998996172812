import React from "react";
import { brands } from "data/data";
import { CATEGORIES } from "data/data";

const ProductsInfo = ({ order, date }) => {
  return (
    <table>
      <tr>
        <th>Product Id</th>
        <td>:{order._id}</td>
      </tr>
      <tr>
        <th>Created Date</th>
        <td>:{date}</td>
      </tr>
      <tr>
        <th>Name</th>
        <td>:{order.name}</td>
      </tr>
      <tr>
        <th>Description</th>
        <td>:{order.description}</td>
      </tr>
      <tr>
        <th>Main Category</th>
        <td>
          :{CATEGORIES.filter((m) => m.id === order.mainCategory)[0].name}
        </td>
      </tr>
      <tr>
        <th>sub Category</th>
        <td>
          :
          {
            CATEGORIES.filter((m) => m.id === order.mainCategory)[0].sub.filter(
              (s) => s.id === order.subCategory
            )[0].name
          }
        </td>
      </tr>
      <tr>
        <th>brand</th>
        <td>:{brands.filter((b) => b.id === order.brand)[0].name}</td>
      </tr>
      <tr>
        <th>Buy Price</th>
        <td>:{order.buyPrice}</td>
      </tr>

      <tr>
        <th>sellPrices</th>
        <td>:{order.sellPrice}</td>
      </tr>
      <tr>
        <th>Min Qty</th>
        <td>:{order.minQty}</td>
      </tr>

      <tr>
        <th>Max Qty</th>
        <td>:{order.maxQty}</td>
      </tr>
      <tr>
        <th>Sold</th>
        <td>:{order.sold}</td>
      </tr>
      <tr>
        <th>promotion</th>
        <td>:{order.promotion} </td>
      </tr>

      <tr>
        <th>rate</th>
        <td>:{order.rate}</td>
      </tr>
      <tr>
        <th>Active</th>
        <td>:{order.active ? "true" :"false" }</td>
      </tr>
    </table>
  );
};

export default ProductsInfo;

