import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

import { Link } from "react-router-dom";

export default function SearchArea(props) {
  const [value, setValue] = useState("");

  useEffect(() => {}, []);

  const handleChange = (event) => {
    setValue(event.currentTarget.value);
  };

  return (
    <div>
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        className={props.TextField}
        onChange={handleChange}
        value={value}
      />
      <Link
        to={value === "" || value === null ? "#" : `/layout/search/${value}`}
        onClick={() => {
          setValue("");
        }}
      >
        <button disabled className={props.SearchIcon}>
          <SearchIcon />
        </button>
      </Link>
    </div>
  );
}
