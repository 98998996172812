import React from "react";

const Errors2 = ({ msg }) => {
  return (
    <p style={{ color: "red", margin: -2, textAlign: "center", fontSize: 12 }}>
      {msg}
    </p>
  );
};

export default Errors2;
