import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";

import { useStyles } from "./commen/style";
import { registerValidationSchema } from "./commen/Validations";
import Errors from "./commen/Errors";
import { register } from "service/authService/authService";
import { loginWithJwt } from "service/authService/authService";
import { useDispatch } from "react-redux";
import { addUser } from "store/actions/User";
import { COLOR } from "util/theme";
import "react-phone-number-input/style.css";

export default function Register(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: "", password: "", fname: "", lname: "",phoneNumber:"" }}
      validationSchema={registerValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        values.isAdmin = "customer";
        setSubmitting(true);
        try {
          const res = await register(values);
          loginWithJwt(res.headers["x-auth-token"]);
          dispatch(addUser(res.headers["x-auth-token"], "A"));
          if (props.value === "cart") {
            props.history.replace("/layout_/cart_checkout");
          } else {
            props.history.replace("/layout_/profile");
          }
          alert("wellcome");
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            alert(ex.response.data);
          } else {
            alert("connection error");
          }
        }
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={classes.root} noValidate onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <div style={{ padding: 20 }}>
              <div class="form-row">
                <div class="col-md-6 mb-3">
                  <label for="validationCustom01">First name</label>
                  <input
                    class="form-control"
                    type="text"
                    onChange={handleChange("fname")}
                    value={values.fname}
                  />
                  {errors.fname ? <Errors msg={errors.fname} /> : null}
                </div>
                <div class="col-md-6 mb-3">
                  <label>Last name</label>
                  <input
                    class="form-control"
                    type="text"
                    onChange={handleChange("lname")}
                    value={values.lname}
                  />
                  {errors.lname ? <Errors msg={errors.lname} /> : null}
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-12 mb-12">
                  <label>Phone Number</label>
                  <input
                    class="form-control"
                    type="number"
                    onChange={handleChange("phoneNumber")}
                    value={values.phoneNumber}
                  />
                  {errors.phoneNumber ? <Errors msg={errors.phoneNumber} /> : null}
                </div>
              </div>


              <div class="form-row">
                <div class="col-md-12 mb-12">
                  <label>E-mail</label>
                  <input
                    class="form-control"
                    type="email"
                    onChange={handleChange("email")}
                    value={values.email}
                  />
                  {errors.email ? <Errors msg={errors.email} /> : null}
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-12">
                  <label>Password</label>
                  <input
                    class="form-control"
                    type="password"
                    onChange={handleChange("password")}
                    value={values.password}
                  />
                  {errors.password ? <Errors msg={errors.password} /> : null}
                </div>
              </div>
              {isSubmitting ? (
                <p>Loading..</p>
              ) : (
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Button
                    disabled={
                      errors.email || errors.password || errors.name
                        ? true
                        : false
                    }
                    variant="contained"
                    color="primary"
                    size="small"
                    type={"submit"}
                    style={{
                      background: COLOR.formButton,
                      color: "white",
                      marginTop: 10,
                    }}
                  >
                    Register
                  </Button>
                </Grid>
              )}
            </div>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
