import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import Labeltext from "./Component/LabelText";
import Divider from "@material-ui/core/Divider";
import DeliveryInfo from "./Component/DeliveryInfo";
import CartInfo from "./Component/CartInfo";
import Totals from "./Component/Totals";
import UserInfo from "./Component/UserInfo";
import { COLOR } from "./../../../../../../../util/theme";
import NextButton from "./Component/NextButton";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  text: {
    margin: 0,
    padding: 5,
  },
}));

export default function Order({ order, history }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleColorChange = (order) => {
    const state = order.state;
    if (state === "procesing") {
      return COLOR.orderProcessing;
    } else if (state === "conformed") {
      return COLOR.orderConformed;
    } else if (state === "send") {
      return COLOR.orderSend;
    } else if (state === "delivered") {
      return COLOR.orderDelivered;
    }
  };
  const Total =
    parseInt(order.cartTotal) +
    parseInt(order.deliveryInfo.fee) +
    parseInt(order.tax);

  var date = new Date(order.date);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();
  var hr = date.getHours();
  var mn = date.getMinutes();
  var sn = date.getSeconds();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  // console.log(
  //   `${year}-${month}-${dt} ${hr}:${mn}:${sn}`
  // );

  return (
    <Grid xs={12} sm={12} style={{ margin: 10 }}>
      <Card style={{ margin: 0, background: handleColorChange(order) }}>
        <CardActions disableSpacing>
          <Labeltext label={"State:"} value={order.state} />
          <Labeltext label={"Order Id:"} value={order._id} />
          <Labeltext
            label={"Date:"}
            value={`${year}-${month}-${dt} ${hr}:${mn}:${sn}`}
          />
          <Labeltext label={"Final Total:"} value={Total.toLocaleString()} />

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <ExpandMoreIcon style={{ height: 20 }} />
          </IconButton>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ background: "white" }}>
            <UserInfo order={order} />
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <DeliveryInfo
              order={order}
              date={`${year}-${month}-${dt} ${hr}:${mn}:${sn}`}
            />
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <CartInfo order={order} />
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <Totals order={order} Total={Total} />

            {order.state !== "delivered" ? (
              <div>
                <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                <NextButton order={order} history={history} />
              </div>
            ) : null}
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}
