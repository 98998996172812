import React from "react";

import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SideMenuItem from "./SideMenuItem";
import { withRouter } from "react-router-dom";
import { MANAGER_DASH } from "data/data";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import { COLOR } from "util/theme";
import { useStyles } from "./SideMenuStyle";
import { useSelector } from "react-redux";

const Catogeries = (props) => {
  const classes = useStyles();
  const USER = useSelector((state) => state.userReducer.USER);

  return (
    <div className={classes.root}>
      {USER && (
        <p
          style={{
            color: "white",
            padding: 5,
            border: "solid",
            textAlign: "center",
            borderRadius: 20,
          }}
        >
          {USER.fname.substring(0, 10)}
        </p>
      )}
      <TreeView
        defaultCollapseIcon={
          <ArrowDropDownIcon
            style={{ color: COLOR.buttonColor, fontSize: 30 }}
          />
        }
        defaultExpandIcon={
          <ArrowRightIcon style={{ color: COLOR.buttonColor, fontSize: 30 }} />
        }
      >
        {USER &&
          MANAGER_DASH.map((c) => (
            <SideMenuItem nodeId={c.id} labelText={c.name} onClick={() => {}}>
              {c.sub &&
                c.sub.map((s) => (
                  <SideMenuItem
                    nodeId={`${s.id}${c.id}`}
                    labelText={s.fname}
                    icon={
                      <ControlCameraIcon
                        style={{ color: COLOR.buttonColor, fontSize: 20 }}
                      />
                    }
                    onClick={() => {
                      props.history.push(s.route);
                    }}
                    style={{
                      marginBottom: 5,
                    }}
                  ></SideMenuItem>
                ))}
            </SideMenuItem>
          ))}
      </TreeView>
    </div>
  );
};
export default withRouter(Catogeries);
