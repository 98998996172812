import React from "react";
import Card from "@material-ui/core/Card";
import Image from "./Components/image";
import Details from "./Components/Details";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import RelatedItem from "views/CustomerView/Commen/RelatedItem/RelatedItem";
import Skeleton from "@material-ui/lab/Skeleton";

const LoadingItemFullView = (props) => {
  return (
    <Grid
      sm={12}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          background: "white",
          maxWidth: 910,
        }}
      >
        <div style={{ padding: 10 }}>
          <Skeleton animation="pulse" height={50} width="100%" />
        </div>
        <Divider style={{ marginBottom: 15 }} />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid sm={6} xs={12}>
            <Image image={null} />
          </Grid>
          <Details item={null} />
        </Grid>
      </Grid>

      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          marginBottom: 20,
          background: "while",
        }}
      >
        <Card style={{ padding: 30 }}>
          <RelatedItem history={props.history} items={null} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoadingItemFullView;
