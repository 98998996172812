import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser } from "../service/authService/authService";
import { useSelector } from "react-redux";

const PrivateRoute = ({ path, component: Component, render, ...rest }) => {
  const USER = useSelector((state) => state.userReducer.USER);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          (USER && getCurrentUser().isAdmin === "admin") ||
          (USER && getCurrentUser().isAdmin === "super")
        ) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
