import React, {  useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import useStyles, { StyledBadge } from "./Style";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SearchArea from "../common/SearchArea/SearchArea";
import MobileDropMenu from "../common/MobileDropMenu/MobileDropMenu";
import { Open, addUser } from "./../../../../../store/actions/User";
import { getJwt } from "./../../../../../service/authService/authService";
import { ViewChange } from "store/actions/User";
import Selection from "./../common/User/Components/Selection";

const SecondNavi = (props) => {
  const dispatch = useDispatch();

  const USER = useSelector((state) => state.userReducer.USER);
  const CART = useSelector((state) => state.cartReducer.CART);
  const cartItem = useSelector((state) => state.cartReducer.CART);
  const total = useSelector((state) => state.cartReducer.TOTAL);
  const classes = useStyles();

  const handleMobileOpen = (event) => {
    if (USER === null) {
      props.history.push("/layout_/login/0");
    } else {
      dispatch(Open(event.currentTarget));
    }
  };

  const handleCartClick = (event) => {
    if (CART.length > 0) {
      props.history.push("/layout/cart");
    } else {
      alert("cart is empty");
    }
  };

  useEffect(() => {
    const jwt = getJwt();
    if (jwt) {
      dispatch(addUser(jwt, "A"));
    }
    dispatch(ViewChange("login"));
  }, [dispatch]);
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={6} sm={3}>
          <a href="/layout/home">
            <img src={require("assets/img/logo.png")} width={90} alt="images"/>
          </a>
        </Grid>

        {props.matches && (
          <Grid
            sm={6}
            xs={6}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div>
              <SearchArea
                TextField={classes.TextField}
                SearchIcon={classes.SearchIcon}
              />
            </div>
          </Grid>
        )}

        <Grid
          sm={3}
          xs={6}
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          {props.matches && (
            <IconButton color="inherit" onClick={handleCartClick}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={
                  <StyledBadge
                    badgeContent={cartItem ? cartItem.length : 0}
                    color="secondary"
                  >
                    <ShoppingCartIcon />
                  </StyledBadge>
                }
              >
                {total.toLocaleString()}
              </Button>
            </IconButton>
          )}

          <Button
            variant="contained"
            className={classes.button}
            onClick={handleMobileOpen}
            startIcon={
              <StyledBadge color="secondary">
                {USER === null ? (
                  <PersonAddOutlinedIcon />
                ) : (
                  <AccountCircleIcon />
                )}
              </StyledBadge>
            }
          ></Button>
        </Grid>
      </Grid>
      {
        <MobileDropMenu>
          <Selection history={props.history} />
        </MobileDropMenu>
      }
    </div>
  );
};
export default withRouter(SecondNavi);
