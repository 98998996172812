import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { COLOR } from "../../../../util/theme";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PopUp from "../../Commen/PopUp/PopUp";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "store/actions/Cart";
import LoadingDetails from "./LoadingDetails";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export default function Details(props) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cartReducer.CART);
  const [open, setOpen] = React.useState(false);
  const { item } = props;
  const [value, setValue] = useState(item && item.minQty);

  const AddToCart = (item) => {
    const obj = {
      _id: item._id,
      name: item.name,
      price: item.sellPrice,
      buyPrice: item.buyPrice,
      image: item.image,
      qty: value,
    };
    dispatch(addItem(obj));
    props.history.push("/layout/cart");
    setOpen(true);
  };

  const checkCart = () => {
    const tt = cartItems.filter((c) => c._id === item._id);
    return tt.length > 0;
  };

  return (
    <Grid sm={6} xs={12} style={{ paddingLeft: 20, paddingRight: 10 }}>
      {item ? (
        <div>
          <h5>{item.name}</h5>
          <Divider style={{ marginBottom: 15 }} />
          <h4
            style={{ color: COLOR.priceColor, fontWeight: 800 }}
          >{`Rs.${item.sellPrice.toLocaleString()}`}</h4>
          {/* <label>size:</label>
          <select>
            <option>M</option>
            <option>L</option>
            <option>S</option>
          </select>
          <label style={{marginRight:5}}>color:</label>
          <select>
            <option>M</option>
            <option>L</option>
            <option>S</option>
          </select> */}
          {/* <p>{item.stock}</p> */}
          {/* <br /> */}
          <label>Quantity:</label>

          <br />
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              disabled={checkCart()}
              onClick={() => {
                const d = value - 1;
                if (d >= item.minQty) {
                  setValue(d);
                }
              }}
            >
              -
            </Button>
            <input
              type="number"
              disabled
              style={{ textAlign: "center", maxWidth: 40 }}
              value={value}
            ></input>
            <Button
              disabled={checkCart()}
              onClick={() => {
                const d = value + 1;
                if (
                  d <= (item.stock > item.maxQty ? item.maxQty : item.stock)
                ) {
                  setValue(d);
                }
              }}
            >
              +
            </Button>
          </ButtonGroup>
          <br />
          <Button
            variant="contained"
            disabled={item.stock > 0 && !checkCart() ? false : true}
            startIcon={<ShoppingCartIcon />}
            style={
              item.stock && !checkCart()
                ? {
                    marginBottom: 8,
                    marginTop: 8,
                    background: COLOR.green,
                    color: "white",
                  }
                : {
                    marginBottom: 8,
                    marginTop: 8,
                  }
            }
            onClick={() => {
              AddToCart(item);
            }}
          >
            <h6 style={{ marginTop: 5, fontWeight: 700 }}>
              {!checkCart() ? "ADD TO CART" : "ADDED"}
            </h6>
          </Button>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={
              item.stock
                ? { margin: 5, color: COLOR.green }
                : { margin: 5, color: "red" }
            }
          >
            <LocalOfferIcon />
            <h5>{item.stock <= 0 ? "Out of Stock" : "In Stock"}</h5>
          </Grid>
          <h5>Descriptions:</h5>
          <h6>{item.description}</h6>
        </div>
      ) : (
        <LoadingDetails />
      )}
      <PopUp
        open={open}
        Duration={1500}
        type={"success"}
        text={"Item Added to the Cart!"}
      />
    </Grid>
  );
}
