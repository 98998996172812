import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "../../../../../util/theme";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 25,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "red",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  buttonText: {
    fontSize: 8,
    color: COLOR.buttonTextColor,
  },
}));

export default function TopNavi() {
  const DATA = [
    { id: 1, name: "About us", route: "/layout_/about_us" },
    { id: 2, name: "Brands", route: "/layout_/brands" },
    { id: 3, name: "Site Map", route: "/layout_/site_map" },
    { id: 4, name: "Contact us", route: "/layout_/contact_us" },
  ];
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ButtonGroup variant="text" style={{ height: 18 }}>
        {DATA.map((d) => (
          <Button size="small" key={d.id} className={classes.button}>
            <Link to={d.route} key={d.id} style={{ textDecoration: "none" }}>
              <h6 className={classes.buttonText}>{d.name}</h6>
            </Link>
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
