import React from "react";
import PrintButton from "./components/PrintButton";

const PDF = ({ children, id }) => {
  return (
    <div>
      <div>{children}</div>
      <PrintButton id={id} label={"Print Order"} />
    </div>
  );
};

export default PDF;
