
import { makeStyles } from "@material-ui/core/styles";
import { COLOR } from "../../../../../util/theme";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "orenge",
    color: "white",
  },
  dd: {
    textTransform: "none",
    color: "white",
    fontSize:17,
    fontWeight:700,
    background:'red',
    border:'solid',
    borderWidth:0.5,

    "&:hover": {
      color: "white",
      background: COLOR.select,
      opacity: 1,
    },
    "&$selected": {
      background: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "white",
      background: COLOR.select,
    },
  },
}));
