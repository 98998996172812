import React from "react";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import { useStyles } from "../style";

const About = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={3}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <h5 className={classes.Topic}>About</h5>
      <Grid container direction="row" justify="center" alignItems="center">
        <a className={classes.link} href={"/layout_/brands"}>
          Brands
        </a>
        <a className={classes.link} href={"/layout_/site_map"}>
          Site map
        </a>
      </Grid>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"https://www.facebook.com/speedystoremart"}
        style={{ color: "white" }}
      >
        {" "}
        <FacebookIcon style={{ color: "white" }} />
        Facebook
      </a>
    </Grid>
  );
};

export default About;
