import HTTP from "service/HttpService/HttpService";
import { LogSave } from "service/ViewLogRequest/ViewLogRequest";

export const AddProducts = async (data) => {
  const res = await HTTP.post(`${HTTP.endPoint}products`, data);
  if (res) {
    await LogSave(`save products id:${res.data._id}`);
  }
  return res.data ? res.data : false;
};

export const getProductById = async (id) => {
  const res = await HTTP.get(`${HTTP.endPoint}products/${id}`);
  return res.data ? res.data : false;
};

export const updateProductById = async (id, data) => {
  const data_object = {
    name: data.name,
    description: data.description,
    buyPrice: data.buyPrice,
    sellPrice: parseInt(data.sellPrice),
    stock: parseInt(data.stock),
    brand: parseFloat(data.brand),
    mainCategory: parseInt(data.mainCat),
    subCategory: parseInt(data.subCat),
    minQty: parseInt(data.minQty),
    maxQty: parseInt(data.maxQty),
    promotion: data.promotion,
  };
  const res = await HTTP.put(`${HTTP.endPoint}products/${id}`, data_object);
  if (res) {
    await LogSave(`update Product By id:${id}`);
  }
  return res.data ? res.data : false;
};

export const saveProducts = async (data) => {
  const res = await HTTP.post(`${HTTP.endPoint}hello`, data);
  return res.data ? res.data : false;
};

export const publishProduct = async (productId) => {
  try {
    const res = await HTTP.put(
      `${HTTP.endPoint}category_item_view_admin/${productId}`,
      {
        active: true,
      }
    );
    if (res) {
      await LogSave(`publish Product id:${productId}`);
    }
    return res.data;
  } catch (error) {
    return "connection error";
  }
};

export const unpublishProduct = async (productId) => {
  try {
    const res = await HTTP.put(
      `${HTTP.endPoint}category_item_view_admin/${productId}`,
      {
        active: false,
      }
    );
    if (res) {
      await LogSave(`publish Product id:${productId}`);
    }
    return res.data;
  } catch (error) {
    return "connection error";
  }
};
