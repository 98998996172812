import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { COLOR } from "../../../../util/theme";

const useStyles = makeStyles({
  root: {
    // maxWidth: 345,
  },
});

export default function Image(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        {props.image ? (
          <div
            className={classes.root}
            style={{
              backgroundImage: `url(${props.item.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              // width:300
            }}
          >
            <div style={{ height: 400, padding: 10 }}>
              {props.item.promotion === "best_sale" && (
                <Avatar
                  style={{
                    padding: 35,
                    background: "yellow",
                    color: "black",
                    fontSize: 15,
                    fontWeight: 700,
                    border: "solid",
                    borderWidth: 1,
                    borderColor: COLOR.ashColor,
                    webkitBoxShadow: "1px 6px 1px #9E9E9E",
                    mozBoxShadow: "1px 6px 10px #9E9E9E",
                    boxShadow: "1px 6px 10px #9E9E9E",
                  }}
                >
                  Best sale
                </Avatar>
              )}
              {props.item.promotion === "on_sale" && (
                <Avatar
                  style={{
                    padding: 35,
                    background: "#51f542",
                    color: "white",
                    fontSize: 15,
                    fontWeight: 700,
                    border: "solid",
                    borderWidth: 1,
                    borderColor: COLOR.ashColor,
                    webkitBoxShadow: "1px 6px 1px #9E9E9E",
                    mozBoxShadow: "1px 6px 10px #9E9E9E",
                    boxShadow: "1px 6px 10px #9E9E9E",
                  }}
                >
                  On sale
                </Avatar>
              )}
              {/* <img src={props.item.image} style={{paddingLeft:60 , width:300}}></img> */}
            </div>
          </div>
        ) : (
          <Skeleton animation={"wave"} height={450} width="100%" />
        )}
      </CardActionArea>
    </Card>
  );
}
