import React from "react";
import { Card } from "@material-ui/core";

export default function Table({ cart, total }) {
  return (
    <Card
      style={{
        height: 240,
        margin: 2,
      }}
    >
      <div
        style={{
          margin: 5,
          padding: 20,
          height: 200,
          overflowY: "scroll",
          overflowX: "scroll",
        }}
      >
        <table border={1}>
          <tr style={{ background: "#dedad1", textAlign: "center" }}>
            <th style={{ width: "85%" }}>Product</th>
            <th style={{ width: "4%" }}>qty</th>
            <th style={{ width: "7%" }}>price</th>
          </tr>
          {cart.map((c) => (
            <tr>
              <td>{c.name}</td>
              <td>{c.qty}</td>
              <td>{c.price}</td>
            </tr>
          ))}
        </table>
      </div>
      <h5 style={{ textAlign: "right", marginRight: 20 }}>
        Rs:{total.toLocaleString()}
      </h5>
    </Card>
  );
}
