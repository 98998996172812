import React from "react";
import { Slide } from "react-slideshow-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  pauseOnHover: true,
  onChange: (oldIndex, nemaxWIndex) => {},
};

const Slideshow = () => {
  const extrasmall = useMediaQuery("(min-width: 360px)");
  const small = useMediaQuery("(min-width: 576px)");
  const medium = useMediaQuery("(min-width: 768px)");
  const large = useMediaQuery("(min-width: 992px)");
  const extralarge = useMediaQuery("(min-width: 1200px)");

  const responsiveWith = () => {
    if (extrasmall && !small && !medium && !large && !extralarge) {
      return 365;
    } else if (extrasmall && small && !medium && !large && !extralarge) {
      return 500;
    } else if (extrasmall && small && medium && !large && !extralarge) {
      return 600;
    } else if (extrasmall && small && medium && large && !extralarge) {
      return 800;
    } else if (extrasmall && small && medium && large && extralarge) {
      return 900;
    }
  };

  // console.log("small", small);
  // console.log("medium", medium);
  // console.log("large", large);
  // console.log("extralarge", extralarge);

  const images = [
    { id: 1, name: "slide_2.jpg" },
    { id: 2, name: "slide_3.jpg" },
    { id: 3, name: "slide_4.jpg" },
  ];

  return (
    <div className="slide-container">
      <Slide {...properties}>
        {images.map((img) => (
          <div className="each-slide" key={img.id}>
            <div className="image-container">
              <img
                src={require(`./images/${img.name}`)}
                style={{ maxWidth: responsiveWith() }}
                alt="images"
              />
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};
export default Slideshow;
