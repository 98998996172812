import React from "react";
import Error from "./Error";

const Input = ({value, handleChange, name, id, type, error, onBlur ,loading}) => {
  return (
    <div>
      <label for="description" className="col-sm-2 col-form-label">
        {name}
      </label>
      <div>
        <input
          type={type}
          className="form-control"
          id={id}
          onChange={handleChange(id)}
          value={value}
          onBlur={onBlur}
          disabled={loading}
        />
        {error && <Error text={error[id]} />}
      </div>
    </div>
  );
};

export default Input;
