import React, { useState } from "react";
import { Formik } from "formik";
import { deliveryValidationSchema } from "./commen/Validations";
import { citys } from "data/data";
import { useDispatch, useSelector } from "react-redux";
import { getDelivery } from "store/actions/Cart";
import FormeForm from "./Form/FormeForm";

export default function ForMe(props) {
  const dispatch = useDispatch();
  const [ordertype, setOrdertype] = useState("me");

  const handleTypeChange = (prop) => (event) => {
    const id = event.target.value;

    setOrdertype(id);
    console.log("sdsdsdsds");
  };

  const DELIVERY_INFO = useSelector(
    (state) => state.cartReducer.DELIVERY_INFOMATION
  );
  const [value, setValue] = React.useState({
    city: "",
    fee: "",
  });

  const handleCityChange = (prop) => (event) => {
    const id = event.target.value;
    if (id) {
      const cityObject = citys.filter((i) => i.id === parseInt(id))[0];
      setValue({ ...value, city: cityObject.name, fee: cityObject.fee });
    } else {
      setValue({ ...value, city: "", fee: "" });
    }
  };

  const handleSenderName = (data) => {
    if (data === "me") {
      return "enter name";
    } else if (data === "gift") {
      return "";
    }
  };
  return (
    <Formik
      initialValues={{
        name: DELIVERY_INFO ? DELIVERY_INFO.name : "",
        phoneNumber: DELIVERY_INFO ? DELIVERY_INFO.phoneNumber : "",
        address: DELIVERY_INFO ? DELIVERY_INFO.address : "",
        paymentMethod: DELIVERY_INFO ? DELIVERY_INFO.paymentMethod : "",
        note: DELIVERY_INFO ? DELIVERY_INFO.note : "",
        sendName: handleSenderName(ordertype),
      }}
      validationSchema={deliveryValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        try {
          values.city = value.city;
          values.fee = value.fee;
          values.type = ordertype;
          dispatch(getDelivery(values));
          props.next();
        } catch (ex) {}
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <FormeForm
          handleCityChange={handleCityChange}
          handleSubmit={handleSubmit}
          errors={errors}
          values={values}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          value={value}
          handleTypeChange={handleTypeChange}
          ordertype={ordertype}
        />
      )}
    </Formik>
  );
}
