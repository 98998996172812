import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
});

export default function Contact() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card style={{ margin: 20, padding: 20 }}>
        <iframe
          title="map22"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.6449216630035!2d79.90623625096255!3d6.812971621594911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNsKwNDgnNDYuNyJOIDc5wrA1NCczMC40IkU!5e0!3m2!1sen!2sus!4v1590252302009!5m2!1sen!2sus"
          width="860"
          height="300"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </Card>
      <Card style={{ margin: 20, padding: 20 }}>
        <Typography variant="h6" gutterBottom>
          <HomeIcon /> Address
        </Typography>
        <Typography variant="body2" gutterBottom>
          Colombo Terminus Consulting (Pvt) Ltd
          <br />
          95H, Wakadawatta
          <br />
          Thumbowila Road
          <br />
          Bokundara
          <br />
          Piliyandala - 10300
        </Typography>
      </Card>

      <Card style={{ margin: 20, padding: 20 }}>
        <Typography variant="h6" gutterBottom>
          <WhatsAppIcon /> phone
        </Typography>

        <Typography variant="body1" gutterBottom>
          +94 11 438 8400
          <br />
          +94 75 377 3377 <br />
        </Typography>
      </Card>
    </div>
  );
}
