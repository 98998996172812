import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { COLOR } from "util/theme";
import { useDispatch } from "react-redux";
import { refresh } from "store/actions/Order";
import {
  updateOrderstate,
  cancelOrder,
} from "service/OrderRequest/OrderRequest";
import BarLoader from "react-spinners/BarLoader";
import { setPrintOrder } from "store/actions/Order";

const NextButton = ({ order, history }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const buttonName = (order) => {
    const state = order.state;
    if (state === "procesing") {
      return "conformed";
    } else if (state === "conformed") {
      return "send";
    } else if (state === "send") {
      return "delivered";
    } else if (state === "delivered") {
      return "Done";
    }
  };

  const handleColorChange = (order) => {
    const state = order.state;
    if (state === "procesing") {
      return COLOR.orderConformed;
    } else if (state === "conformed") {
      return COLOR.orderSend;
    } else if (state === "send") {
      return COLOR.orderDelivered;
    } else if (state === "delivered") {
      return null;
    } else if (state === "cancel") {
      return null;
    }
  };

  const handleNoClick = async () => {
    var retVal = window.confirm("Do you want to continue ?");
    if (retVal) {
      setLoading(true);
      const data = await updateOrderstate(order._id, buttonName(order));

      if (data) {
        setLoading(false);
        dispatch(refresh());
      }
    } else {
      return null;
    }
  };

  const handleCancel = async () => {
    var retVal = window.confirm("Do you want to continue ?");
    if (retVal) {
      setLoading(true);
      const data = await cancelOrder(order._id);

      if (data) {
        setLoading(false);
        dispatch(refresh());
      }
    } else {
      return null;
    }
  };

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      {order.state !== "delivered" ? (
        <div>
          {!loading ? (
            <div>
              <Button
                variant="contained"
                style={{marginRight: 10 }}
                onClick={() => {
                  history.push("/admin/print_order");
                  dispatch(setPrintOrder(order));
                }}
              >
                Print
              </Button>
              {order.state !== "cancel" && (
                <Button
                  variant="contained"
                  style={{ background: handleColorChange(order) }}
                  onClick={() => {
                    handleNoClick();
                  }}
                >
                  {buttonName(order)}
                </Button>
              )}
              {order.state !== "cancel" && (
                <Button
                  variant="contained"
                  style={{ background: "red", marginLeft: 10 }}
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
          ) : (
            <BarLoader color={"#123abc"} loading={loading} />
          )}
        </div>
      ) : null}
    </Grid>
  );
};

export default NextButton;
