import React, { useEffect, useState } from "react";
import { getUsers } from "service/UserHandle/UserHandle";
import User from "./User";
import { useSelector } from 'react-redux';

const ViewArea = () => {
  const [loaging, setLoading] = useState(false);
  const [uses, setUses] = useState(false);
  const refresh_key = useSelector(state=>state.userReducer.ADMIN_CREATE_REFRESH_KEY);
  // console.log("refresh_key",refresh_key);
  // console.log("user",user);

  useEffect(() => {
    const GetResult = async () => {
        setUses(false);
      setLoading(true);
      const data = await getUsers();
      if (data) {
        setLoading(false);
        setUses(data);
      }
    };
    GetResult();
    // console.log("user", typeof uses);
  }, [refresh_key]);
  return (
    <div style={{ padding: 20 }}>
      {!loaging ? (
        <div>
          {typeof uses === "object" ? (
            <div>
              {uses.map((u) => (
        <User u={u}  />
              ))}
            </div>
          ) : (
            <div>{uses}</div>
          )}
        </div>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
};

export default ViewArea;
