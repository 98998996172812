import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PaymentIcon from "@material-ui/icons/Payment";
import {
  useQontoStepIconStyles,
  ColorlibConnector,
  useColorlibStepIconStyles,
  useStyles,
} from "./Style";
import DeliveryInfo from "./Components/DeliveryInfo/DeliveryInfo";
import Payment from "./Components/Payment/Payment";
import Finish from "./Components/Finish/Finish";

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <ContactMailIcon />,
    2: <PaymentIcon />,
    3: <CheckCircleOutlineOutlinedIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return ["Delivery info", "Payment", "Finish"];
}

function getStepContent(step, next, back, finish) {
  switch (step) {
    case 0:
      return <DeliveryInfo next={next} />;
    case 1:
      return <Payment next={next} back={back} />;
    case 2:
      return <Finish finish={next} />;
    default:
      return "Unknown step";
  }
}

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    if (activeStep === steps.length) {
      props.history.push("/");
    }
  }, [steps, activeStep,props.history]);

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>{getStepContent(activeStep, handleNext, handleBack)}</div>
    </div>
  );
}
