import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import GridContainer from "../../../components/Grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import { getOrder } from "service/OrderRequest/OrderRequest";
import User from "./Components/UserInfo";
import Order from "./Components/Order/Order";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  text: {
    textAlign: "center",
    fontWeight: 500,
  },
});

export default function Profile(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.USER);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [conformed, setConformed] = useState(false);
  const [send, setSend] = useState(false);
  const [delivered, setDelivered] = useState(false);

  // const

  const [order, setOrder] = React.useState(null);

  React.useEffect(() => {
    const GetResult = async () => {
      setLoading(true);
      if (user) {
        const res = await getOrder(user._id);
        if (res) {
          setOrder(res);
          setLoading(false);
          if (typeof res === "object") {
            const processingData = res.filter((o) => o.state === "procesing");
            const conformedData = res.filter((o) => o.state === "conformed");
            const sendData = res.filter((o) => o.state === "send");
            const deliveredData = res.filter((o) => o.state === "delivered");
            setProcessing(processingData);
            setConformed(conformedData);
            setSend(sendData);
            setDelivered(deliveredData);
          }
        }
      } else {
        props.history.push("/");
      }
    };
    GetResult();
  }, [user,props.history]);


  
  


  return (
    <GridContainer>
      <Grid
        container
        direction=""
        justify="flex-start"
        alignItems="flex-start"
        className={classes.root}
        style={{ background: "white", marginTop: 10, padding: 10 }}
      >
        <Grid xs={12} sm={12}>
          <User order={typeof order === "object" ? order : []} />
        </Grid>
        <Grid xs={12} sm={12}>
          {loading && (
            <h1 style={{ textAlign: "center", margin: 200 }}>Loading....</h1>
          )}

          {processing.length > 0 && (
            <p className={classes.text}>
              {`${processing.length} Processing order`}:{" "}
              <span style={{ fontWeight: 100 }}>
                (Orders that succecfully sent to the SPEEDY STORE MART)
              </span>
            </p>
          )}
          {processing.length > 0 && processing.map((o) => <Order order={o}   />)}
          {conformed.length > 0 && (
            <p className={classes.text}>
              {`${conformed.length} conformed order`}{" "}
              <span style={{ fontWeight: 100 }}>
                (Orders that conformed by SPEEDY STORE MART)
              </span>
            </p>
          )}
          {conformed.length > 0 && conformed.map((o) => <Order order={o}   />)}
          {send.length > 0 && (
            <p className={classes.text}>
              {`${send.length} send order`}{" "}
              <span style={{ fontWeight: 100 }}>
                (Orders that sent from the SPEEDY STORE MART)
              </span>
            </p>
          )}
          {send.length > 0 && send.map((o) => <Order order={o}   />)}
          {delivered.length > 0 && (
            <p className={classes.text}>
              {`${delivered.length} delivered order`}{" "}
              <span style={{ fontWeight: 100 }}>
                (Orders that succecfully Delivered to your home)
              </span>
            </p>
          )}
          {delivered.length > 0 && delivered.map((o) => <Order order={o} />)}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.root}
        style={{
          background: "white",
          marginTop: 10,
          padding: 10,
          marginBottom: 10,
        }}
      ></Grid>
    </GridContainer>
  );
}
