import React from "react";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Chip from "@material-ui/core/Chip";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useStyles } from "./commen/style";
import { useSelector, useDispatch } from "react-redux";
import { Close } from "store/actions/User";
import { logout } from "service/authService/authService";
import { RemoveUser } from "store/actions/User";

export default function Selection(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const USER = useSelector((state) => state.userReducer.USER);
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container direction="column" justify="center" alignItems="center">
        <Chip
          // style={{ background: COLOR.formButton }}
          icon={<AccountCircleIcon />}
          label={USER ? USER.fname : ""}
          style={{ marginTop: 10, width: 200 }}
          onClick={() => {
            props.history.push("/layout_/profile");
            dispatch(Close());
          }}
        />
        <Chip
          icon={<ExitToAppIcon />}
          label="logout"
          style={{ marginTop: 10, width: 200 }}
          onClick={() => {
            logout();
            dispatch(Close());
            dispatch(RemoveUser());
            props.history.push("/");
          }}
        />
      </Grid>
    </form>
  );
}
