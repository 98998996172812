import { ITEMS } from "./../../data/items";
import HTTP from "service/HttpService/HttpService";

export const getSubNaviBarItem = () => {
  const subNaviBarItems = ITEMS.filter((i) => i.id <= 12);

  return subNaviBarItems;
};

export const GetSubNaviBarItem = async (string, page) => {
  var res = [];
  const size = 4;
  switch (string) {
    case "new_added_items":
      res = await HTTP.get(`${HTTP.endPoint}new_added_items/${page}/${size}`);
      return res;
    case "best_selling":
      res = await HTTP.get(
        `${HTTP.endPoint}best_selling_items/${page}/${size}`
      );
      return res;
    case "promotion":
      res = await HTTP.get(`${HTTP.endPoint}promotion/${page}/${size}`);
      return res;
    case "on_sale":
      res = await HTTP.get(`${HTTP.endPoint}on_sale/${page}/${size}`);
      return res;
    default:
      return res;
  }
};

export const NewAddedItems = async () => {
  const res = await HTTP.get(`${HTTP.endPoint}new_added_items`);
  return res;
};

const SubNaviBarRequest = {
  getSubNaviBarItem,
  GetSubNaviBarItem,
  NewAddedItems,
};

export default SubNaviBarRequest;
