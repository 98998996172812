import React from "react";

const Labeltext = ({ label, value }) => {
  return (
    <React.Fragment>
      <p
        style={{
          textAlign: "start",
          marginTop: 5,
          marginRight: 2,
          fontWeight: 700,
          color: "#424242",
         
        }}
      >
        {label}
      </p>
      <p
        style={{
          textAlign: "start",
          marginTop: 5,
         marginRight: 6,
         fontWeight: 200,
        //  color:'white'
        }}
      >
        {value}
      </p>
    </React.Fragment>
  );
};

export default Labeltext;
