import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { SaveOrder } from "service/OrderRequest/OrderRequest";
import { clearCart, lastOrder } from "store/actions/Cart";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function Payment(props) {
  const dispatch = useDispatch();
  const DELIVERY_INFO = useSelector(
    (state) => state.cartReducer.DELIVERY_INFOMATION
  );
  const TOTAL = useSelector((state) => state.cartReducer.TOTAL);
  const CART = useSelector((state) => state.cartReducer.CART);
  const USER = useSelector((state) => state.userReducer.USER);
  const classes = useStyles();
  const tax =
    DELIVERY_INFO.paymentMethod === "cardPayment" ? (TOTAL * 3.5) / 100 : 0;
  const TAX = parseInt(tax);
  const FinalTotal = DELIVERY_INFO.fee + TOTAL + TAX;

  return (
    <div className={classes.root}>
      <Card style={{ marginBottom: 10, padding: 20 }}>
        <table border={1}>
          <tr style={{ background: "#dedad1", textAlign: "center" }}>
            <th style={{ width: "14%" }}></th>
            <th style={{ width: "85%" }}></th>
          </tr>

          <tr>
            <td>{"Name"}</td>
            <td>{DELIVERY_INFO.name}</td>
          </tr>
          <tr>
            <td>{"Phone"}</td>
            <td>{DELIVERY_INFO.phoneNumber}</td>
          </tr>
          <tr>
            <td>{"address"}</td>
            <td>{DELIVERY_INFO.address}</td>
          </tr>
          <tr>
            <td>{"city"}</td>
            <td>{DELIVERY_INFO.city}</td>
          </tr>
          <tr>
            <td>{"Delivey charges"}</td>
            <td>{DELIVERY_INFO.fee}</td>
          </tr>
          {DELIVERY_INFO.paymentMethod === "cardPayment" && (
            <tr>
              <td>{"Tax"}</td>
              <td>{TAX}</td>
            </tr>
          )}
          <tr>
            <td>{"bill total"}</td>
            <td>{TOTAL.toLocaleString()}</td>
          </tr>
        </table>
        <h4 style={{ marginTop: 10, color: "green" }}>
          Final Total :{FinalTotal.toLocaleString()}
        </h4>
      </Card>

      <Card style={{ marginBottom: 10, padding: 20 }}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 10 }}
            onClick={() => {
              props.back();
            }}
          >
            BACK
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 10 }}
            onClick={async () => {
              const res = await SaveOrder(
                USER,
                DELIVERY_INFO,
                CART,
                TOTAL,
                DELIVERY_INFO.fee,
                "procesing",
                DELIVERY_INFO.type,
                TAX
              );

              if (res) {
                props.next();
                dispatch(clearCart());
                dispatch(lastOrder(res));
              }
            }}
          >
            NEXT
          </Button>
        </Grid>

        <h5>Terms & Conditions</h5>
        <p>
          <ul>
            <li>
              There could be variations in the colors and design in the actual
              product when compared to the product displayed on the site.
            </li>
            <li>
              Any order placed before 03pm will be delivered on the following
              day. Orders which is placed after 03pm will be delivered within 48
              hours
            </li>
            <li>
              It is the customer’s responsibility to ensure that there is a
              party available to accept the order at the point of delivery.
              There is no refund policy after the order placed.
            </li>
            <li>
              Once the order is placed and confirmed, speedystoremart will be
              not in a position to cancel it.
            </li>
            <li>
              Any issue related to any product should be brought to the notice
              of speedystoremart within 01 hour of the purchase.
            </li>
            <li>
              Speedystoremart management reserves the exclusive right to decide
              on refunding or replacing a product.
            </li>
            <li>
              On acceptance of the order the full amount should be paid in cash.
            </li>
            <li>
              In the event of customer living in a apartment complex the
              customers should come to the entrance to receive the delivery.
            </li>
            <li>
              Speedystoremart will not be responsible for any delays due to
              restrictions government law enforcement or due to any unavoidable
              circumstances.
            </li>
          </ul>
        </p>
      </Card>
    </div>
  );
}
