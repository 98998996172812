import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import Labeltext from "./Component/LabelText";
import Divider from "@material-ui/core/Divider";
import DeliveryInfo from "./Component/ProductsInfo";
import UserInfo from "./Component/UserInfo";

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  text: {
    margin: 0,
    padding: 5,
  },
}));

export default function Order({ product }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  var date = new Date(product.date);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();
  var hr = date.getHours();
  var mn = date.getMinutes();
  var sn = date.getSeconds();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  console.log(`${year}-${month}-${dt} ${hr}:${mn}:${sn}`);

  return (
    <Grid xs={12} sm={12} style={{ margin: 10 }}>
      <Card style={{ margin: 0 }}>
        <CardActions disableSpacing>
        <img src={product.image} style={{ maxWidth: 60, marginRight: 20 }} alt="images"/>

          <Labeltext label={"Name:"} value={product.name} />
          <Labeltext label={"Stock:"} value={product.stock} />
          <Labeltext label={"Sold:"} value={product.sold} />
          <Labeltext label={"Brand:"} value={product.brand} />



          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <ExpandMoreIcon style={{ height: 20 }} />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ background: "white" }}>
            <UserInfo order={product} />
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <img src={product.image} style={{ maxWidth: 200, marginRight: 20 }} alt="images"/>
            {/* <p>{product.image} </p> */}
            <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            <DeliveryInfo
              order={product}
              date={`${year}-${month}-${dt} ${hr}:${mn}:${sn}`}
            />
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
}

