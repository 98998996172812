import React from "react";
import IconBadge from "./Icon_Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { COLOR } from "util/theme";

const OrderNotification = ({ obj, classes, onClick }) => {
  return (
    <React.Fragment>
      {obj.procesing > 0 && (
        <IconBadge classes={classes} obj={obj.procesing} onClick={onClick}>
          <NotificationsIcon style={{ color: COLOR.orderProcessing }} />
        </IconBadge>
      )}
      {obj.conformed > 0 && (
        <IconBadge classes={classes} obj={obj.conformed} onClick={onClick}>
          <NotificationsIcon style={{ color: COLOR.orderConformed }} />
        </IconBadge>
      )}
      {obj.send > 0 && (
        <IconBadge classes={classes} obj={obj.send} onClick={onClick}>
          <NotificationsIcon style={{ color: COLOR.orderSend }} />
        </IconBadge>
      )}
      {obj.delivered > 0 && (
        <IconBadge classes={classes} obj={obj.delivered} onClick={onClick}>
          <NotificationsIcon style={{ color: COLOR.orderDelivered }} />
        </IconBadge>
      )}
    </React.Fragment>
  );
};

export default OrderNotification;
