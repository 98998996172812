import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import { useStyles } from "./commen/style";
import { Formik } from "formik";
import { loginValidationSchema } from "./commen/Validations";
import { login } from "../../../service/authService/authService";
import { useDispatch } from "react-redux";
import Errors from "./commen/Errors";
import { addUser } from "store/actions/User";
import { COLOR } from "../../../util/theme";
import { ViewChange } from "store/actions/User";
import jwtDecode from "jwt-decode";
import { LogSave } from "service/ViewLogRequest/ViewLogRequest";

export default function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={loginValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        try {
          const res = await login(values.email, values.password);
          dispatch(addUser(res, "A"));
          const USER = jwtDecode(res);

          if (USER && (USER.isAdmin === "admin" || USER.isAdmin === "super")) {
            props.history.replace("/admin/all_summery");
            try {
              await LogSave("login user");
            } catch (ex) {
              if (ex.response && ex.response.status === 400) {
                alert(ex.response.data);
              } else {
                alert("connection error userLogSave");
              }
            }
          } else {
            alert("Welcome to Speedy Store Mart");
            if (props.value === "cart") {
              props.history.replace("/layout_/cart_checkout");
            } else {
              props.history.replace("/layout_/profile");
            }
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            alert(ex.response.data);
          } else {
            alert("connection error");
          }
        }
        resetForm();
        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={classes.root} onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <AccountCircleIcon />
            <TextField
              label="e-mail"
              type="email"
              variant="outlined"
              size="small"
              onChange={handleChange("email")}
              value={values.email}
              helperText={errors.email ? <Errors msg={errors.email} /> : null}
            />
            <TextField
              label="Password"
              type="password"
              onChange={handleChange("password")}
              value={values.password}
              variant="outlined"
              size="small"
              helperText={
                errors.password ? <Errors msg={errors.password} /> : null
              }
            />
            <p
              onClick={() => {
                dispatch(ViewChange("reset"));
              }}
            >
              {/* <u>forget password</u> */}
            </p>
            {isSubmitting ? (
              <p>loading</p>
            ) : (
              <Button
                disabled={errors.email || errors.password ? true : false}
                variant="contained"
                size="small"
                type={"submit"}
                style={{ background: COLOR.formButton, color: "white" }}
              >
                Login
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
}
