import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import Splash from "components/Splash";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
});

export default function Finish(props) {
  const classes = useStyles();
  const order = useSelector((state) => state.cartReducer.L_ORDER);
  // console.log("sdsddssdd", order && order.deliveryInfo);
  return (
    <div className={classes.root}>
      {order ? (
        <>
          {order.deliveryInfo.paymentMethod !== "cardPayment" && (
            <Card style={{ margin: 20, padding: 20 }}>
              <Typography variant="h6" gutterBottom>
                Thank you
              </Typography>

              {order && (
                <Typography variant="body1" gutterBottom>
                  {`order Id - ${order._id}`}
                </Typography>
              )}
              <Typography variant="body2" gutterBottom>
                It is greatly appreciated your kind interest in visiting and
                patronizing our site speedy store mart and we will assure you of
                our best attention at all times.
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={() => {
                    props.finish();
                  }}
                >
                  Back to Home
                </Button>
              </Grid>
            </Card>
          )}
          {order.deliveryInfo.paymentMethod === "cardPayment" && (
            <Card style={{ margin: 20, padding: 20 }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Splash order={order} />
              </Grid>
            </Card>
          )}
        </>
      ) : (
        <p>loading...</p>
      )}
    </div>
  );
}
