import React from "react";

const UserInfo = ({order}) => {
  return (
    <table>
      <tr>
        <th>User Id</th>
        <td>:{order.userId}</td>
      </tr>
      
  
    </table>
  );
};

export default UserInfo;
