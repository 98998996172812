import React from "react";
import IconButton from "@material-ui/core/IconButton";

const Icon_Button = ({ children, classes, menuId, handleProfileMenuOpen }) => {
  return (
    <div className={classes}>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        {children}
      </IconButton>
    </div>
  );
};

export default Icon_Button;
