import React from "react";
import Grid from "@material-ui/core/Grid";
import CreateArea from "./Components/CreateArea";
import ViewArea from "./Components/ViewArea";
const CreateAdmin = () => {
  return (
    <Grid container direction="row" justify="center" alignItems="flex-start">
      <Grid xs={12} sm={6}>
        <ViewArea />
      </Grid>
      <Grid xs={12} sm={6}>
        <CreateArea />
      </Grid>
    </Grid>
  );
};

export default CreateAdmin;
