import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import HomePage from "views/CustomerView/HomePage/HomePage";

import Categories from "views/CustomerView/Commen/Categeories/Catogeries";
import Grid from "@material-ui/core/Grid";
import { Switch, Route, Redirect } from "react-router-dom";
import MainCategoriesView from "views/CustomerView/MainCategoriesView/MainCategoriesView";
import SubNaviBarItemView from "views/CustomerView/SubNaviBarItemView/SubNaviBarItemView";
import ItemFullView from "views/CustomerView/ItemFullView/ItemFullView";
import FirstCartView from "views/CustomerView/Cart/FirstCartView/FirstCartView";
import SearchView from "views/CustomerView/SearchView/SearchView";
import GridContainer from "components/Grid/GridContainer";

import { COLOR } from "util/theme";
import TopNavi from "./../Components/NaviBar/TopNaviBar/TopNavi";
import SecondNavi from "../Components/NaviBar/SecondNavi/SecondNavi";
import MobileNavi from "../Components/NaviBar/MobileNavi/MobileNavi";
import Footer from "./../Components/Footer/Footer";
import TabBar from "./../Components/NaviBar/TabBar/TabBar";

const Items = (props) => {
  const matches = useMediaQuery("(min-width:790px)");
  return (
    <div style={{ background: "#F5F2F2" }}>
      <div style={{ background: COLOR.subColor }}>
        <TopNavi />
      </div>
      <GridContainer
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 15,
          paddingRight: 10,
          background: "white",
        }}
      >
        <SecondNavi matches={matches} history={props.history} />
      </GridContainer>
      {!matches && <MobileNavi history={props.history} />}
      {matches && (
        <GridContainer>
          <TabBar />
        </GridContainer>
      )}

      <div style={{ background: "#F5F2F2" }}>
        <GridContainer>
          {matches && (
            <Grid sm={2} style={{ marginTop: 5 }}>
              <Categories history={props.history} />
            </Grid>
          )}
          <Switch>
            <Route path="/layout/home" exact component={HomePage} />
            <Route
              path="/layout/main_categories/:main/:sub"
              component={MainCategoriesView}
            />
            <Route
              path="/layout/sub_menu/:name"
              component={SubNaviBarItemView}
            />
            <Route
              path="/layout/view_item/:id/:main/:sub"
              component={ItemFullView}
            />
            <Route path="/layout/cart" component={FirstCartView} />
            <Route path="/layout/search/:value" component={SearchView} />
            <Route path="/" exact component={HomePage} />
            <Redirect from="/" to="/layout/home" />
          </Switch>
        </GridContainer>
      </div>

      <Footer history={props.history} />
    </div>
  );
};

export default Items;
