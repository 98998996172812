import React, { useState} from "react";
import {
  getProductById,
  updateProductById,
} from "service/ProductsRequest/ProductsRequest";
import ReadOnlyData from "./Components/ReadOnlyData";
import Section1 from "./Components/Section_1";
import Section2 from "./Components/Section_2";
import SeachArea from "./Components/SeachArea";

const Update_product = () => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    id: "",
    name: "",
    image: "",
    description: "",
    stock: "",
    buyPrice: "",
    sellPrice: "",
    brand: "",
    mainCat: "",
    subCat: "",
    minQty: "",
    maxQty: "",
    promotion: "none",
    rate: "",
    date: "",
    userId: "",
    sold: "",
  });

  const handleChange = (prop) => (event) => {
    const name = event.target.value;
    setValues({ ...values, [prop]: name });
  };

  const handleCheck = (values) => {
    if (
      values["name"] === "" ||
      values["description"] === "" ||
      values["buyPrice"] === "" ||
      values["sellPrice"] === "" ||
      values["minQty"] === "" ||
      values["maxQty"] === "" ||
      values.name.length > 50 ||
      values.name.length < 5 ||
      values.description.length > 70 ||
      values.description.length < 5 ||
      values.buyPrice <= 0 ||
      values.sellPrice <= 0 ||
      values.maxQty <= 0 ||
      values.minQty <= 0
    ) {
      return false;
    } else if (values["stock"] <= values["maxQty"]) {
      alert("Stock <= Max Qty !");
      return false;
    } else if (values["buyPrice"] >= values["sellPrice"]) {
      alert(" Buy Price >= Sell Price !");
      return false;
    } else if (values["maxQty"] <= values["minQty"]) {
      alert(" max Qty <= min Qty !");
      return false;
    } else {
      return true;
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      const res = await getProductById(values.id);
      if (res) {
        setValues({
          ...values,
          id: res._id,
          name: res.name,
          image: res.image,
          description: res.description,
          stock: res.stock,
          buyPrice: res.buyPrice,
          sellPrice: res.sellPrice,
          brand: res.brand,
          mainCat: res.mainCategory,
          subCat: res.subCategory,
          minQty: res.minQty,
          maxQty: res.maxQty,
          promotion: res.promotion,
          rate: res.rate,
          date: res.date,
          userId: res.userId,
          sold: res.sold,
        });
      }
      setLoading(false);
      setData(true);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        alert(ex.response.data);
      } else {
        alert("connection error");
      }
      setValues({ ...values, id: "" });
      setLoading(false);
    }
  };
  const handleClick = async () => {
    if (!handleCheck(values)) return null;

    setLoading(true);
    const result = await updateProductById(values.id, values);
    if (result) {
      alert("Update successfully !");
      window.location.reload();
      setLoading(false);
    } else {
      alert("Error not update");
      window.location.reload();
      setLoading(false);
    }
  };
  return (
    <div style={{ padding: 20 }}>
      <SeachArea
        values={values}
        handleSearch={handleSearch}
        handleChange={handleChange}
        loading={loading}
      />

      {data && (
        <div style={{ padding: 20 }}>
          <ReadOnlyData values={values} />
          <Section1
            values={values}
            handleChange={handleChange}
            loading={loading}
          />
          <Section2
            values={values}
            handleChange={handleChange}
            loading={loading}
          />

          {!loading ? (
            <div className="form-group row">
              <div className="col-sm-10">
                <button
                  style={{ marginRight: 10 }}
                  className="btn btn-primary"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  Upload
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Clear All
                </button>
              </div>
            </div>
          ) : (
            <p>loading</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Update_product;
