import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SubCategories(props) {
  const classes = useStyles();
  const [ setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          // value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          value={props.currentSubId - 1}
        >
          {props.subCategory &&
            props.subCategory.map((s) => (
              <Link
                style={{ textDecoration: "none" }}
                to={`/layout/main_categories/${props.mainCat}/${s.id}`}
              >
                <Tab label={s.name} />
              </Link>
            ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
