import React from "react";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import MainLayout from "./Layout/MainLayout/index";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import cartReducer from "./store/reducers/Cart";
import itemsReducer from "./store/reducers/Items";
import userReducer from "./store/reducers/User";
import orderReducer from "./store/reducers/Order";

var hist = createBrowserHistory();
const rootReducer = combineReducers({
  cartReducer: cartReducer,
  itemsReducer: itemsReducer,
  userReducer: userReducer,
  orderReducer: orderReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {
  return (
    // <Splash/>
    <Provider store={store}>
      <BrowserRouter history={hist}>
        <MainLayout history={hist} />
        {/* <DashBoard history={hist} /> */}
      </BrowserRouter>
    </Provider>
  );
}

export default App;
