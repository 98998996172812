import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PopUp = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.open === true) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, props.Duration);
    }
  }, [props.open, props.Duration]);

  return (
    <Snackbar open={open} autoHideDuration={props.Duration}>
      <Alert severity={props.type}>{props.text}</Alert>
    </Snackbar>
  );
};

export default PopUp;
