import React from "react";
import { Grid, Card } from "@material-ui/core";
import { COLOR } from "util/theme";
import Divider from "@material-ui/core/Divider";

const TableView = (props) => {
  return (
    <Grid xs={12} sm={6} style={{ padding: 10 }}>
      <Card
        style={{
          border: "solid",
          borderColor: COLOR.ashColor,
          background: props.color,
        }}
      >
        <p style={{ margin: 0, textAlign: "center", fontWeight: 700 }}>
          {props.name}
        </p>
        <Divider />
        {props.products && (
          <div
            style={{
              margin: 5,
              padding: 20,
              height: 200,
              overflowY: "scroll",
              background: "white",
            }}
          >
            <table border={1}>
              <tr>
                <th>image</th>
                <th>name</th>
                <th>price</th>
                <th>qty</th>
              </tr>
              {props.products.map((r) => (
                <tr>
                  <td>
                    <img src={r.image} width={50} alt="images" />
                  </td>
                  <td>{r.name}</td>
                  <td>{r.sellPrice}</td>
                  <td>{r.stock}</td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </Card>
    </Grid>
  );
};

export default TableView;
