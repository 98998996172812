import HTTP from "service/HttpService/HttpService";

export const CategoryWiseItem = async (mainCat, subCat, page) => {
  const { data } = await HTTP.get(
    `${HTTP.endPoint}category_wise_item/${mainCat}/${subCat}/${page}/4`
  );

  return data.content.length > 0
    ? { data: data.content, total: data.total }
    : "Sorry this Catergory Items are Finished , Stock will come soon";
};

export const MainCategoryWiseItem = async (mainCat, page) => {
  const { data } = await HTTP.get(
    `${HTTP.endPoint}category_item_view_admin/${parseInt(mainCat)}/${parseInt(
      page
    )}/${4}/${1}`
  );
  return data.content.length > 0 ? data : "No items";
};
export const BrandWiseItem = async (mainCat, page) => {
  const { data } = await HTTP.get(
    `${HTTP.endPoint}brand_wise_item/${parseInt(mainCat)}/${parseInt(
      page
    )}/${4}`
  );

  return data.content.length > 0 ? data : "No items";
};

export const MainCategoryWiseItemPending = async (mainCat, page) => {
  const { data } = await HTTP.get(
    `${HTTP.endPoint}category_item_view_admin/${parseInt(mainCat)}/${parseInt(
      page
    )}/${4}/${0}`
  );
  return data.content.length > 0 ? data : "No items";
};
