import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    backgroundColor: "#424242",
    paddingTop: 15,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5,
    minHeight: 1200,
    maxWidth: 255,
  },
});

export const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: "#6e6e6e",
      color: "white",
      fontWeight: 700,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: "#424242",
      color: "white",
      fontWeight: 700,
    },
  },
  content: {
    color: "white",
    background: "#6e6e6e",
    borderColor: "#6e6e6e",
    borderRadius: 5,

    paddingRight: theme.spacing(1),
    marginBottom: 5,
    fontWeight: 600,
    fontSize: 8,
    border: "solid",
    borderWidth: 0.5,
    "$expanded > &": {},
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));
