import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Required fill this field"),
  password: Yup.string().required("Required fill this field"),
});


export const registerValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Required fill this field"),
  fname: Yup.string().required("Required fill this field"),
  lname: Yup.string().required("Required fill this field"),
  phoneNumber:Yup.number().min(9).required("Phone number Required"),
  password: Yup.string().required("Required fill this field"),

});


export const resetValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Required fill this field"),
});
