import { REFRESH, ORDER_COUNT, PRINT_ORDER } from "store/actions/Order";

const initialState = {
  key: 0,
  COUNT: false,
  print_order: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH:
      const newstate = state.key + 1;
      return { ...state, key: newstate };

    case PRINT_ORDER:
      return { ...state, print_order: action.order };

    case ORDER_COUNT:
      return { ...state, COUNT: action.data };
    default:
      return state;
  }
};

export default orderReducer;
