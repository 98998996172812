import React, { useState, useEffect } from "react";
import SelectionAreas from "./Components/SelectionArea";
import { adminSearch } from "service/OrderRequest/OrderRequest";
import ItemView from "./Components/ItemView";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";

export default function NativeSelects() {
  const [values, setValues] = React.useState({
    state: "",
    city: "",
    from: "",
    to: "",
  });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalitems, setTotalitems] = useState(0);

  // const getData = async (Page) => {
  //   setLoading(true);

  //   var newTo = "";
  //   var newFrom = "";

  //   if (values.to !== "" && values.from !== "") {
  //     newTo = new Date(values.to).toISOString();
  //     newFrom = new Date(values.from).toISOString();
  //   }

  //   const searchData = {
  //     state: values.state,
  //     city: values.city,
  //     to: newTo,
  //     from: newFrom,
  //     page: Page,
  //     size: 5,
  //   };
  //   const res = await adminSearch(searchData);

  //   if (res) {
  //     setContent(res.content);
  //     setPage(res.page);
  //     setTotalitems(res.total);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    const GetResult = async () => {
      setLoading(true);

      var newTo = "";
      var newFrom = "";

      if (values.to !== "" && values.from !== "") {
        newTo = new Date(values.to).toISOString();
        newFrom = new Date(values.from).toISOString();
      }

      const searchData = {
        state: values.state,
        city: values.city,
        to: newTo,
        from: newFrom,
        page: page,
        size: 5,
      };
      const res = await adminSearch(searchData);

      if (res) {
        setContent(res.content);
        setPage(res.page);
        setTotalitems(res.total);
      }
      setLoading(false);
    };
    if (page > 0) {
      GetResult();
    }
  }, [page, values.city, values.from, values.state, values.to]);

  const handleChange = (prop) => (event) => {
    const name = event.target.value;
    setValues({ ...values, [prop]: name });
  };

  const handleSearchButton = async () => {
    setLoading(true);

    var newTo = "";
    var newFrom = "";

    if (values.to !== "" && values.from !== "") {
      newTo = new Date(values.to).toISOString();
      newFrom = new Date(values.from).toISOString();
    }

    const searchData = {
      state: values.state,
      city: values.city,
      to: newTo,
      from: newFrom,
      page: 1,
      size: 5,
    };
    const res = await adminSearch(searchData);

    if (res) {
      setContent(res.content);
      setPage(res.page);
      setTotalitems(res.total);
    }
    setLoading(false);
  };

  return (
    <div>
      <SelectionAreas
        handleChange={handleChange}
        values={values}
        handleSearchButton={handleSearchButton}
      />
      {loading ? (
        <div style={{ padding: 250 }}>
          <BarLoader
            css={override}
            // width={100}
            color={"#123abc"}
            loading={loading}
          />
        </div>
      ) : (
        <ItemView
          content={content}
          page={page}
          handleChange={handlePageChange}
          totalitems={totalitems}
        />
      )}
    </div>
  );
}
