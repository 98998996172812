import React from "react";
import GridContainer from "components/Grid/GridContainer";
import { Card } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import OneItem from "../OneItem/OneItem";
import Skeleton from "@material-ui/lab/Skeleton";
import LoadingOneItem from "../OneItem/LoadingOneItem";

const NewAdditios = (props) => {
  const loading = props.items === null ? true : false;

  return (
    <Card>
      {!loading ? (
        <div style={{ padding: 10 }}>
          <h5>NEW ADDITIONS</h5>
        </div>
      ) : (
        <Skeleton animation="wave" height={40} width="100%" />
      )}

      <Divider />
      <Divider style={{ marginBottom: 10 }} />
      {!loading ? (
        <GridContainer style={{ paddingTop: 20 }}>
          {props.items.map((c) => (
            <OneItem xs={6} sm={4} md={3} history={props.history} item={c} />
          ))}
        </GridContainer>
      ) : (
        <GridContainer>
          {[1, 2, 3, 4].map((c) => (
            <LoadingOneItem xs={6} sm={4} md={3} />
          ))}
        </GridContainer>
      )}
    </Card>
  );
};

export default NewAdditios;
