import React from "react";
import GridContainer from "components/Grid/GridContainer";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Switch, Route, Redirect } from "react-router-dom";
import CartCheckOut from "views/CustomerView/Cart/CartCheckOut/CartCheckOut";
import AboutUs from "views/CustomerView/About/AboutUs";
import Contact from "views/CustomerView/Contact/Contact";
import SiteMap from "views/CustomerView/SiteMap/SiteMap";
import Brand from "views/CustomerView/Brand/Brand";
import Profile from "views/CustomerView/Profile/Profile";
import User from "views/CustomerView/User/User";
import { COLOR } from "util/theme";
import TopNavi from "./../Components/NaviBar/TopNaviBar/TopNavi";
import SecondNavi from "../Components/NaviBar/SecondNavi/SecondNavi";
import MobileNavi from "../Components/NaviBar/MobileNavi/MobileNavi";
import Footer from "./../Components/Footer/Footer";
import TabBar from "./../Components/NaviBar/TabBar/TabBar";

const Details = (props) => {
  const matches = useMediaQuery("(min-width:790px)");
  return (
    <div style={{ background: "#F5F2F2" }}>
      <div style={{ background: COLOR.subColor }}>
        <TopNavi />
      </div>
      <GridContainer
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
          background: "white",
        }}
      >
        <SecondNavi matches={matches} history={props.history} />
      </GridContainer>
      {!matches && <MobileNavi history={props.history} />}
      {matches && (
        <GridContainer>
          <TabBar />
        </GridContainer>
      )}

      <div
        style={{
          background: "#F5F2F2",
        }}
      >
        <GridContainer>
          <Switch>
            <Route path="/layout_/cart_checkout" component={CartCheckOut} />
            <Route path="/layout_/about_us" component={AboutUs} />
            <Route path="/layout_/brands" component={Brand} />
            <Route path="/layout_/site_map" component={SiteMap} />
            <Route path="/layout_/contact_us" component={Contact} />
            <Route path="/layout_/profile" component={Profile} />
            <Route path="/layout_/login/:key" component={User} />

            <Redirect to="/layout/home" />
          </Switch>
        </GridContainer>
      </div>

      <Footer history={props.history} />
    </div>
  );
};

export default Details;
