import {
  SET_USER,
  CLOSE_MENU,
  OPEN_MENU,
  VIEW_CHANGE,
  REMOVE_USER,
  ADMIN_CREATE,
} from "./../actions/User";

const initialState = {
  ADMIN_CREATE_REFRESH_KEY: 0,
  USER: null,
  MENU_STATE: null,
  VIEW_STATE: { login: false, register: false, reset: true },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, USER: action.user };

    case CLOSE_MENU:
      // console.log("CLOSE_MENU");
      return { ...state, MENU_STATE: null };

    case OPEN_MENU:
      // console.log("OPEN_MENU");
      return { ...state, MENU_STATE: action.value };

    case VIEW_CHANGE:
      // console.log("VIEW_STATE", action.obj);
      return { ...state, VIEW_STATE: action.obj };

    case REMOVE_USER:
      return { ...state, USER: null };

    case ADMIN_CREATE:
      return {
        ...state,
        ADMIN_CREATE_REFRESH_KEY: state.ADMIN_CREATE_REFRESH_KEY + 1,
      };    default:
      return state;
  }
};

export default userReducer;
