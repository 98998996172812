import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import { useStyles } from "./commen/style";
import { Formik } from "formik";
import { resetValidationSchema } from "./commen/Validations";
import Errors from "./commen/Errors";
import { COLOR } from "../../../util/theme";

export default function Reset({ handleLogin }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={resetValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        try {
          // await auth.login(values.email, values.password);
          // window.location = "/home";
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            const errors = { ...this.state.errors };
            errors.username = ex.response.data;
          }
        }
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          resetForm();
          setSubmitting(false);
        }, 500);
      }}
    >
      {({ values, errors, handleChange }) => (
        <form className={classes.root}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <AccountCircleIcon />
            <TextField
              label="e-mail"
              type="email"
              variant="outlined"
              size="small"
              onChange={handleChange("email")}
              value={values.email}
              helperText={errors.email ? <Errors msg={errors.email} /> : null}
            />

            <Button
              disabled={errors.email ? true : false}
              variant="contained"
              color="primary"
              size="small"
              style={{ background: COLOR.formButton, color: "white" }}
              onClick={() => {
                alert(values.email, values.email);
              }}
            >
              send
            </Button>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

/*
    <form className={classes.root} noValidate>
      <Grid container direction="column" justify="center" alignItems="center">
        <AccountCircleIcon />
        <TextField
          id="outlined-search"
          label="e-mail"
          type="email"
          variant="outlined"
          size="small"
        />
        <p
          onClick={() => {
            handleLogin();
          }}
        >
          <u>already have account</u>
        </p>
        <Button variant="contained" color="primary" size="small">
          Submit
        </Button>
      </Grid>
    </form>
    */
