import * as Yup from "yup";

export const deliveryValidationSchema = Yup.object().shape({
  name: Yup.string().min(4).max(20).required("Name Required"),
  phoneNumber: Yup.string()
    .min(9)
    .max(10)
    .required("valid phone number required"),
  address: Yup.string().min(10).max(60).required("Address Required"),
  paymentMethod: Yup.string().required("Payment Method Required"),
  note: Yup.string().min(10).max(160),
  sendName: Yup.string().min(4).max(20).required("Name Required"),
});
