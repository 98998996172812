import React from "react";
import { Grid } from "@material-ui/core";

const CartInfo = ({ order }) => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <table border={1}>
        <tr>
          <td style={{ paddingLeft: 10, paddingRight: 10 }}>Name</td>
          <td style={{ paddingLeft: 10, paddingRight: 10 }}>unit price</td>
          <td style={{ paddingLeft: 10, paddingRight: 10 }}>qty</td>
          <td style={{ paddingLeft: 10, paddingRight: 10 }}>sun total </td>
        </tr>
        {order.cart.map((c) => (
          <tr>
            <td>
              <p>{c.name}</p>
            </td>
            <td>
              <p>{c.price}</p>
            </td>
            <td>
              <p>{c.qty}</p>
            </td>
            <td>
              <p>{c.qty * c.price}</p>
            </td>
          </tr>
        ))}
      </table>
    </Grid>
  );
};

export default CartInfo;
