export const ADD_ITEM = "ADD_ITEM";
export const INITE = "INITE";
export const REMOVE = "REMOVE";
export const DELIVERY_INF = "DELIVERY_INF";
export const REMOVE_CART = "REMOVE_CART";
export const LAST_ORDER = "LAST_ORDER";

export const addItem = (item) => {
  return { type: ADD_ITEM, item };
};

export const initializCart = () => {
  return { type: INITE };
};

export const removeItem = (item) => {
  return { type: REMOVE, item };
};

export const getDelivery = (info) => {
  return { type: DELIVERY_INF, info };
};

export const lastOrder = (order) => {
  return { type: LAST_ORDER, order };
};

export const clearCart = () => {
  return { type: REMOVE_CART };
};
