import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  Topic: { textAlign: "center", marginTop: 10, color: "black" },
  link: {
    textAlign: "left",
    color: "white",
    borderRight: "solid",
    borderWidth: 0.5,
    borderColor: "white",
    paddingRight: 15,
    paddingLeft: 15,
  },
});
