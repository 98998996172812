import React, { useState } from "react";
import { AddProducts } from "service/ProductsRequest/ProductsRequest";
import ImageUploader from "./Components/ImageUploader";
import Section1 from "./Components/Sections/Section_1";
import Section2 from "./Components/Sections/Section_2";
import Section3 from "./Components/Sections/Section_3";
import Section4 from "./Components/Sections/Section_4";
import { formValidation } from "./Components/Validation";
import { Formik } from "formik";
import { useStyles } from "./Components/style";
import Button from "@material-ui/core/Button";
import { COLOR } from "util/theme";
import { useSelector } from "react-redux";

const AddProduct = () => {
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const USER = useSelector((state) => state.userReducer.USER);

  const classes = useStyles();

  const handelImage = (file) => {
    setImageFile(file);
  };
  const handleCheck = (values, file) => {
    if (values["stock"] <= values["maxQty"]) {
      alert("Stock <= Max Qty !");
      return false;
    } else if (values["buyPrice"] >= values["sellPrice"]) {
      alert(" Buy Price >= Sell Price !");
      return false;
    } else if (file === "" || file === null) {
      alert(" Please pick an image !");
      return false;
    } else if (values["maxQty"] <= values["minQty"]) {
      alert(" max Qty <= min Qty !");
      return false;
    } else {
      return true;
    }
  };

  return (
    <Formik
      initialValues={{
        id: "",
        name: "",
        // image: "",
        description: "",
        stock: "",
        buyPrice: "",
        sellPrice: "",
        brand: "",
        mainCat: "",
        subCat: "",
        minQty: "",
        maxQty: "",
        promotion: "",
      }}
      validationSchema={formValidation}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (!handleCheck(values, imageFile)) return null;

        setSubmitting(true);
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append("_id", values.id);
          formData.append("name", values.name);
          formData.append("description", values.description);
          formData.append("stock", parseInt(values.stock));
          formData.append("buyPrice", parseFloat(values.buyPrice));
          formData.append("sellPrice", parseInt(values.sellPrice));
          formData.append("brand", parseInt(values.brand));
          formData.append("mainCategory", parseInt(values.mainCat));
          formData.append("subCategory", parseInt(values.subCat));
          formData.append("minQty", parseInt(values.minQty));
          formData.append("maxQty", parseInt(values.maxQty));
          formData.append("promotion", values.promotion);
          formData.append("image", imageFile);
          formData.append("user", USER.name);

          const result = await AddProducts(formData);

          if (result) {
            alert("Product created !");
            setLoading(false);
            window.location.reload();
          } else {
            alert("Error Product not created !");
            setLoading(false);
            //  window.location.reload();
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            alert(ex.response.data);
            setLoading(false);
            // console.log("erororerer", ex.response.data);
          } else {
            // console.log("erororerer", ex);
            alert("connection error Product not created ");
            setLoading(false);
          }
        }
        setImageFile(null);
        resetForm();
        setSubmitting(false);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={classes.root} onSubmit={handleSubmit}>
          <div style={{ padding: 20 }}>
            <Section1
              values={values}
              handleChange={handleChange}
              // onBlur={runValidation}
              errors={errors}
              loading={loading}
            />
            <ImageUploader
              center
              id="image"
              onInput={handelImage}
              errorText="Please pick an image."
              loading={loading}
            />
            <Section2
              values={values}
              handleChange={handleChange}
              // onBlur={runValidation}
              errors={errors}
              loading={loading}
            />
            <Section3
              values={values}
              handleChange={handleChange}
              // onBlur={runValidation}
              errors={errors}
              loading={loading}
            />
            <Section4
              values={values}
              handleChange={handleChange}
              // onBlur={runValidation}
              errors={errors}
              loading={loading}
            />

            {isSubmitting ? (
              <p>loading</p>
            ) : (
              <Button
                // disabled={}
                variant="contained"
                size="small"
                type={"submit"}
                style={{ background: COLOR.formButton, color: "white" }}
              >
                Create
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddProduct;
