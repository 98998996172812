import HTTP from "service/HttpService/HttpService";
import { getCurrentUser } from "service/authService/authService";

export const viewAllLog = async () => {
  try {
    const res = await HTTP.get(`${HTTP.endPoint}logs`);
    return res.data.length > 0 ? res.data : " no logs";
  } catch (error) {
    return "connection error";
  }
};

export const LogSave = async (activity) => {
  const USER = getCurrentUser();

  const data = {
    activity: activity,
    userId: USER._id,
    userEmail: USER.email,
  };

  const res = await HTTP.post(`${HTTP.endPoint}logs`, data);
  console.log("USER", res);
  return res;
};
