import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import NewAdditions from "../Commen/NewAdditios/NewAdditios";
import BestSellingItems from "../Commen/BestSellingItems/BestSellingItems";
import AboutUs from "../Commen/AboutUsShort/AboutUsShort";
import Card from "@material-ui/core/Card";
import ItemsList from "./Components/ItemsList/ItemsList";
import { CATEGORIES } from "data/data";
import { CategoryWiseItem } from "service/MainCategoryRequestsa/MainCategoryRequestsa";
import {
  BestSellItems,
  NewAddedItems,
} from "service/HomePageRequestsa/HomePageRequestsa";

const MainCategoriesView = (props) => {
  const [categoryWiseItem, setCategoryWiseItem] = useState(null);
  const [bestSellItems, setBestSellItems] = useState(null);
  const [newAdd, setNewAdd] = useState(null);
  const [page, setPage] = React.useState(1);
  const [totalitems, setTotalitems] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const getCategory = () => {
    var mainCategory = CATEGORIES.filter(
      (c) => c.id === props.match.params.main
    );
    if (mainCategory.length === 0) {
      mainCategory = CATEGORIES.filter((c) => c.id === 1);
      return mainCategory;
    } else if (mainCategory.length === 1) {
      return mainCategory;
    }
  };

  const getSubCategory = () => {
    var subCategory = getCategory()[0].sub.filter(
      (s) => s.id === props.match.params.sub
    );

    if (subCategory.length === 0) {
      subCategory = getCategory()[0].sub.filter((s) => s.id === 1);
      return subCategory;
    } else if (subCategory.length === 1) {
      return subCategory;
    }
  };

  const mainCat = getCategory()[0].id;
  const subCat = getSubCategory()[0].id;

  useEffect(() => {
    const GetResult = async () => {
      const { data: bestSellItems } = await BestSellItems();
      const { data: newItems } = await NewAddedItems();
      const catItems = await CategoryWiseItem(mainCat, subCat, page);

      if (bestSellItems && newItems) {
        setCategoryWiseItem(null);
        setTotalitems(catItems.total);
        setBestSellItems(bestSellItems);
        setNewAdd(newItems);
        if (typeof catItems.data === "object") {
          setCategoryWiseItem(catItems.data);
        } else if (typeof catItems === "string") {
          setCategoryWiseItem(catItems);
        }
      }
    };
    GetResult();
    setPage(1);
  }, [props.match, mainCat, subCat, page]);

  useEffect(() => {
    const GetResult = async () => {
      setCategoryWiseItem(null);
      const catItems = await CategoryWiseItem(mainCat, subCat, page);
      if (bestSellItems) {
        setCategoryWiseItem(catItems.data);
      }
    };
    GetResult();
  }, [page, bestSellItems, mainCat, subCat]);

  return (
    <Grid
      sm={10}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid style={{ marginLeft: 20, marginTop: 20 }}>
        <div>
          <h2
            style={{
              padding: 25,
              textAlign: "center",

              color: "white",
              background:
                "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
            }}
          >
            {getCategory()[0].name}
          </h2>
        </div>
      </Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "white" }}>
        <ItemsList
          mainCat={getCategory()[0]}
          subCat={getSubCategory()[0]}
          items={categoryWiseItem}
          handleChange={handleChange}
          totalitems={totalitems}
          page={page}
          history={props.history}
        />
      </Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <BestSellingItems items={bestSellItems} history={props.history} />
      </Grid>

      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <NewAdditions items={newAdd} history={props.history} />
      </Grid>
      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          marginBottom: 20,
          background: "while",
        }}
      >
        <Card style={{ padding: 30 }}>
          <AboutUs />
        </Card>
      </Grid>
    </Grid>
  );
};

export default MainCategoriesView;
