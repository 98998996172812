import React from "react";
import Error from "./Error";

const Section_1 = ({ values, handleChange, loading }) => {
  return (
    <React.Fragment>
      <div className="form-group row">
        <label for="name" className="col-sm-2 col-form-label">
          Name
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="name"
            onChange={handleChange("name")}
            value={values.name}
            required
            disabled={loading}
          />
          {values.name === "" ||
          values.name.length > 50 ||
          values.name.length < 5 ? (
            <Error text={"5 - 10 letters  name Requred"} />
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <label for="description" className="col-sm-2 col-form-label">
          Description
        </label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            id="description"
            onChange={handleChange("description")}
            value={values.description}
            required
            disabled={loading}
          />
          {values.description === "" ||
          values.description.length > 70 ||
          values.description.length < 5 ? (
            <Error text={"5 - 70 letters  Description Requred"} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section_1;
