import React from "react";
import { useSelector } from "react-redux";
import PDF from "./pdf_c/PDF";
import Divider from "@material-ui/core/Divider";
import UserInfo from "./components/UserInfo";
import DeliveryInfo from "./components/DeliveryInfo";
import CartInfo from "./components/CartInfo";
import Totals from "./components/Totals";
import logo from "assets/img/22.png";

const OrderPrint = () => {
  const ORDER = useSelector((state) => state.orderReducer.print_order);

  var date = new Date(ORDER.date ? ORDER.date : "2020-01-01");
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();
  var hr = date.getHours();
  var mn = date.getMinutes();
  var sn = date.getSeconds();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  const Total = parseInt(ORDER.cartTotal) + parseInt(ORDER.deliveryInfo.fee);

  return (
    <div style={{ margin: 20 }}>
      <PDF id={`Speedy store mart order id:${ORDER._id}`}>
        <div
          id={`Speedy store mart order id:${ORDER._id}`}
          style={{
            paddingLeft: 30,
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <table>
            <tr>
              <td>
                <img src={logo} alt="images" />
              </td>
            </tr>
            <tr>
              <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            </tr>
            <tr>
              <UserInfo
                order={ORDER}
                date={`${year}-${month}-${dt} ${hr}:${mn}:${sn}`}
              />
            </tr>
            <tr>
              <DeliveryInfo order={ORDER} />
            </tr>
            <tr>
              <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            </tr>
            <tr>
              <CartInfo order={ORDER} />
            </tr>
            <tr>
              <Divider style={{ marginTop: 15, marginBottom: 15 }} />
            </tr>
            <tr>
              <Totals order={ORDER} Total={Total} />
            </tr>
          </table>
        </div>
      </PDF>
    </div>
  );
};

export default OrderPrint;
