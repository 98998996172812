import React from "react";
import Error from "./Error";

const Select = ({ handleChange, name, id, options, error ,loading}) => {
  return (
    <div>
      <label for="brand" className="col-sm-2 col-form-label">
        {name}
      </label>
      <select
        id={id}
        className="form-control"
        onChange={handleChange(id)}
        required
        disabled={loading}
      >
        <option selected value="">
          {`Select ${name}`}
        </option>
        {options.map((b) => (
          <option value={parseInt(b.id)}>{b.name}</option>
        ))}
      </select>
      {error && <Error text={error[id] ? `${name} Required` : null} />}
    </div>
  );
};

export default Select;
