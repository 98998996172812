import React from "react";
import Menu from "@material-ui/core/Menu";
import { useSelector, useDispatch } from "react-redux";
import { Close } from "./../../../../../../store/actions/User";

const MobileDropMenu = ({ children }) => {
  const dispatch = useDispatch();
  const anchorEl = useSelector((state) => state.userReducer.MENU_STATE);

  const isMobileOpen = Boolean(anchorEl);
  return (
    <Menu
      anchorEl={anchorEl}
      elevation={0}
      getContentAnchorEl={null}
      id={"menu-mobile"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMobileOpen}
      onClose={() => {
        dispatch(Close());
      }}
    >
      <div style={{ margin: 10 }}>{children}</div>
    </Menu>
  );
};

export default MobileDropMenu;
