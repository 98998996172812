import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import ItemList from "../Commen/BestSellingItems/BestSellingItems";
import NewAdditions from "../Commen/NewAdditios/NewAdditios";
import AboutUs from "../Commen/AboutUsShort/AboutUsShort";
import Card from "@material-ui/core/Card";
import CoverPhoto from "./Components/CoverPhoto/CoverPhoto";
import { useSelector } from "react-redux";
import SearchView from "../SearchView/SearchView";

import {
  BestSellItems,
  NewAddedItems,
} from "../../../service/HomePageRequestsa/HomePageRequestsa";

const HomePage = (props) => {
  const [bestSellItems, setBestSellItems] = useState(null);
  const [newAdd, setNewAdd] = useState(null);
  const SEARCH_ITEMS = useSelector((state) => state.itemsReducer.SEARCH_RESULT);
  const USER = useSelector((state) => state.userReducer.USER);

  useEffect(() => {
    if (USER !== null) {
      if (USER.isAdmin === "super" || USER.isAdmin === "admin")
        props.history.push("/admin/all_summery");
    }
  }, [USER, props.history]);

  useEffect(() => {
    const GetResult = async () => {
      const { data: bestSellItem } = await BestSellItems();
      const { data: newAddedItem } = await NewAddedItems();

      if (bestSellItem && newAddedItem) {
        setBestSellItems(bestSellItem);
        setNewAdd(newAddedItem);
      }
    };
    GetResult();
  }, []);

  return (
    <Grid
      sm={10}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "white" }}>
        {SEARCH_ITEMS === false && <CoverPhoto />}
        {SEARCH_ITEMS.length > 0 && <SearchView />}
      </Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <ItemList history={props.history} items={bestSellItems} />
      </Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <Alert variant="filled" severity="success">
          This is a new added items — check it out!
        </Alert>
      </Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <NewAdditions history={props.history} items={newAdd} />
      </Grid>
      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          marginBottom: 20,
          background: "while",
        }}
      >
        <Card style={{ padding: 30 }}>
          <AboutUs />
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomePage;
