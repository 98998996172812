import React from "react";
import Error from "../FormItems/Error";
const Section_4 = ({ handleChange, values, onBlur, errors, loading }) => {
  return (
    <div className="row" style={{ margin: 20 }}>
      <div className="col-md-3 mb-3">
        <label for="promotion">Promotion</label>
        <select
          id="promotion"
          class="form-control"
          required
          onChange={handleChange("promotion")}
          disabled={loading}
        >
          <option selected value={"none"}>
            None
          </option>
          <option selected value={"on_sale"}>
            On Sale
          </option>
          <option selected value={"best_sale"}>
            Best Sale
          </option>
        </select>
        {errors && (
          <Error text={errors["promotion"] ? "Select Promotion" : null} />
        )}
      </div>
    </div>
  );
};

export default Section_4;
