import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TableArea from "./Components/TableArea/TableArea";

import AboutUs from "../../Commen/AboutUsShort/AboutUsShort";

import { cartViewRelatedItems } from "service/RelatedItemsRequest/RelatedItemsRequest";
import { useSelector } from "react-redux";
import CartRelatedItems from "../CartRelatedItems/CartRelatedItems";

const FirstCartView = (props) => {
  const CART = useSelector((state) => state.cartReducer.CART);
  const [relatedItems, setRelatedItems] = useState(null);

  useEffect(() => {
    const GetResult = async () => {
      const data = await cartViewRelatedItems();
      if (data) {
        setRelatedItems(data);
      }
    };
    GetResult();

    if (CART.length <= 0) {
      props.history.push("/");
    }
  }, [CART,props.history]);

  return (
    <Grid
      sm={10}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "white" }}>
        <TableArea history={props.history} />
      </Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <CartRelatedItems history={props.history} items={relatedItems} />
      </Grid>

      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          marginBottom: 20,
          background: "while",
        }}
      >
        <Card style={{ padding: 30 }}>
          <AboutUs history={props.history} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default FirstCartView;
