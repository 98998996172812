import * as Yup from "yup";

export const formValidation = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  stock: Yup.number().min(1).required(),
  buyPrice: Yup.number().min(1).required(),
  sellPrice: Yup.number().min(1).required(),
  brand: Yup.number().required(),
  mainCat: Yup.number().required(),
  subCat: Yup.number().required(),
  minQty: Yup.number().min(1).required(),
  maxQty: Yup.number().min(1).required(),
  promotion: Yup.string().required(),
});

