import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import useStyles from "./MobileNaviStyle";
import Grid from "@material-ui/core/Grid";
import Categories from "views/CustomerView/Commen/Categeories/Catogeries";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchArea from "../common/SearchArea/SearchArea";

const PrimarySearchAppBar = (props) => {
  const classes = useStyles();
  const cart = useSelector((state) => state.cartReducer.CART);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMobileOpen = Boolean(anchorEl);

  const handleMobileClose = () => {
    setAnchorEl(null);
  };

  const handleMobileOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobile = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileOpen}
      onClose={handleMobileClose}
    >
      <div style={{ margin: 10 }}>
        <Categories history={props.history} />
      </div>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar style={{ background: "red" }}>
          <Grid sm={2} xs={1}>
            <IconButton
              onClick={handleMobileOpen}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
          </Grid>

          <Grid
            sm={8}
            xs={10}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <div>
              <SearchArea
                TextField={classes.TextField}
                SearchIcon={classes.SearchIcon}
              />
            </div>
          </Grid>
          <Grid
            sm={2}
            xs={1}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
                onClick={() => {
                  if (cart.length > 0) {
                    props.history.push("/layout/cart");
                  } else {
                    alert("Cart is empty");
                  }
                }}
              >
                <Badge
                  badgeContent={cart.length > 0 ? cart.length : 0}
                  color="primary"
                >
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>

      {renderMobile}
    </div>
  );
};
export default withRouter(PrimarySearchAppBar);
