import { getDashboardOrderDetails } from "service/DashBoardRequest/DashBoardRequest";
export const REFRESH = "REFRESH";
export const ORDER_COUNT = "ORDER_COUNT";
export const PRINT_ORDER = "PRINT_ORDER";

export const refresh = () => {
  return { type: REFRESH };
};

export const setPrintOrder = (order) => {
  return { type: PRINT_ORDER, order };
};

export const LoadData = () => {
  return async (dispatch) => {
    const res = await getDashboardOrderDetails();
    dispatch({
      type: ORDER_COUNT,
      data: res,
    });
  };
};
