import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // maxWidth: 500,
  },
});

export default function AboutUs() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card style={{ margin: 20, padding: 20 }}>
        <h2>About us</h2>

        <p>
          Colombo Terminus Consulting pvt Ltd has spread their wings towards a
          new direction by launching speedy store mart online shopping portal to
          bridge the gap between store and you. and we will bring the ultimate
          level of shopping in to your fingertips.
        </p>
        <p>
          The story behind www.speedystoremart.com worth knowing as it defines
          the difference of us to any other shopping portal. This story connects
          with emotions and values to each purchase you do with us.
        </p>
        <p>
          The two narrators of the story shared their experience being away from
          their family & loved ones. They felt so helpless on the day of their
          daughters’ birthday when all the efforts failed in sending a gift.
          That pain made them realized there are many parents, sibling, kids,
          friends & lovers who feel the same & they laid this foundation.
        </p>
        <p>
          The company Colombo Terminus Consulting Pvt Ltd who is powered this
          unique online shopping portal is one of the leading Destination
          Management Company in Colombo and operated over 20 years experienced
          management team in the hospitality industry This will give you the
          assurance that www.speedystoremart.com will have the best services
          available through their immense experience and guidance.
        </p>
      </Card>
      <Card style={{ margin: 20, padding: 20 }}>
        <h2>Terms & Conditions</h2>

        <p>
          <ol>
            <li>
              There could be variations in the colors and design in the actual
              product when compared to the product displayed on the site.
            </li>
            <br />
            <li>
              Any order placed before 03pm will be delivered on the following
              day. Orders which is placed after 03pm will be delivered within 48
              hours
            </li>
            <br />
            <li>
              It is the customer’s responsibility to ensure that there is a
              party available to accept the order at the point of delivery.
              There is no refund policy after the order placed.
            </li>
            <br />
            <li>
              Once the order is placed and confirmed, speedystoremart will be
              not in a position to cancel it.
            </li>
            <br />
            <li>
              Any issue related to any product should be brought to the notice
              of speedystoremart within 01 hour of the purchase.
            </li>
            <br />
            <li>
              Speedystoremart management reserves the exclusive right to decide
              on refunding or replacing a product.
            </li>
            <br />
            <li>
              On acceptance of the order the full amount should be paid in cash.
            </li>
            <br />
            <li>
              In the event of customer living in a apartment complex the
              customers should come to the entrance to receive the delivery.
            </li>
            <br />
            <li>
              Speedystoremart will not be responsible for any delays due to
              restrictions government law enforcement or due to any unavoidable
              circumstances.
            </li>
          </ol>
        </p>
      </Card>
      <Card style={{ margin: 20, padding: 20 }}>
        <h2>Privacy Policy</h2>

        <p>
          At Speedystoremart (hereinafter referred to as “MyStore”, “us”, “we”,
          “our”) we are committed to safeguarding and preserving the privacy of
          our visitors and to comply with any applicable data protection and
          privacy laws. The Privacy Policy applies to all Customers and Users
          (collectively referred to as “Users. This Privacy Policy explains what
          happens to any personal data that you provide to us, or that we
          collect from you whilst you visit our Site. We do update this Policy
          from time to time so please do review this Policy regularly.
        </p>
        <h4>Information We Collect</h4>
        <p>
          In running and maintaining our Site we may collect and process the
          following data about you:
          <ul>
            <li>
              Information about your use of our Site including details of your
              visits such as pages viewed and the resources that you access.
              Such information includes traffic data, location data and other
              communication data.
            </li>
            <li>
              Information provided voluntarily by you. For example, when you
              register for information or make a purchase.
            </li>
            <li>
              Information that you provide when you communicate with us by any
              means.
            </li>
          </ul>
          If you have previously purchased goods or services from us, we may
          provide to you details of similar goods or services, or other goods
          and services, which you may be interested in. Where your consent has
          been provided in advance we may allow selected Third Parties to use
          your data to enable them to provide you with information regarding
          unrelated goods and services which we believe may interest you. Where
          such consent has been provided it can be withdrawn by you at any time.
        </p>
        <h4>Storing Your Personal Data</h4>
        <p>
          In operating our Website it may become necessary to transfer data that
          we collect from you to Third Parties. By providing your personal data
          to us, you agree to this transfer, storing or processing. We do our
          upmost to ensure that all reasonable steps are taken to make sure that
          your data is treated stored securely. Unfortunately the sending of
          information via the internet is not totally secure and on occasion
          such information can be intercepted. We cannot guarantee the security
          of data that you choose to send us electronically. Sending such
          information is entirely at your own risk.
        </p>
        <h4>Disclosing Your Information</h4>
        <p>
          We will not disclose your personal information to any other party
          other than in accordance with this Privacy Policy and in the
          circumstances detailed below:
          <ul>
            <li>
              In the event that we sell any or all of our business to the buyer.
            </li>
            <li>
              Where we are legally required by law to disclose your personal
              information.
            </li>
            <li>To further fraud protection and reduce the risk of fraud.</li>
          </ul>
        </p>
      </Card>
    </div>
  );
}
