import HTTP from "service/HttpService/HttpService";

export const getUsers = async () => {
  const res = await HTTP.get(`${HTTP.endPoint}users`);
  return res.data ? res.data : "No Data";
};

export const changePassword = async (email, password, newPw) => {
  const res = await HTTP.post(`${HTTP.endPoint}change_password`, {
    email: email,
    password: password,
    newPw: newPw,
  });
  return res.data ? res.data : "No Data";
};
