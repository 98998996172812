import React  from "react";
import Grid from "@material-ui/core/Grid";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";

const OrderTypeselection = ({ handleChange, values }) => {
  return (
    <>
      <RadioGroup
        aria-label="quiz"
        name="quiz"
        value={values}
        onChange={handleChange("type")}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <FormControlLabel
            value="me"
            control={<Radio />}
            label={
              <div
                className="row"
                style={{
                  paddingLeft: 10,
                  paddingTop: 15,
                  marginRight: 5,
                }}
              >
                <p>Order for me</p>
                <PersonPinIcon />
              </div>
            }
          />
          <FormControlLabel
            value="gift"
            control={<Radio />}
            label={
              <div
                className="row"
                style={{
                  paddingLeft: 10,
                  paddingTop: 15,
                  marginRight: 5,
                }}
              >
                <p>Order for Gift</p>
                <CardGiftcardIcon />
              </div>
            }
          />
        </Grid>
      </RadioGroup>
    </>
  );
};

export default OrderTypeselection;
