import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: 500,
  },
});

export default function AboutUs() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>
        About us
      </Typography>

      <Typography variant="body1" gutterBottom>
        Colombo Terminus Consulting pvt Ltd has spread their wings towards a new
        area launching speedy store mart online shopping portal to bridge the
        gap between store, and you and we bring the ultimate level of shopping
        in to your fingertips....<a href={"/layout_/about_us"}>read more</a>
      </Typography>
    </div>
  );
}
