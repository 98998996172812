export const SEARCH_ITEMS = "SEARCH_ITEMS";
export const SEARCH_CLEAR = "SEARCH_CLEAR";

export const clearResult = () => {
  return { type: SEARCH_CLEAR };
};

export const getResult = (items) => {
  return { type: SEARCH_ITEMS, items };
};
