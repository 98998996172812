import React from "react";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./Style";

import OnSale from "./Components/OnSale";
import BestSale from "./Components/BestSale";

const useStyles = makeStyles(styles);

const OneItem = (props) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  return (
    <GridItem
      xs={props.xs}
      sm={props.sm}
      md={props.md}
    >
      {!loading ? (
        <div
          className={classes.card}
          onClick={() => {
            setLoading(true);
            props.history.push(
              `/layout/view_item/${props.item._id}/${props.item.mainCategory}/${props.item.subCategory}`
            );
            setLoading(false);
          }}
        >
          <div>
            <div
              className={classes.root}
              style={{
                backgroundImage: `url(${props.item.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div style={{ height: 160, padding: 10 }}>
                {props.item.promotion === "best_sale" && <BestSale />}
                {props.item.promotion === "on_sale" && <OnSale />}
              </div>
            </div>
            <div style={{ marginBottom: 5, padding: 5 }}>
              <h6
                style={{
                  textAlign: "left",
                  fontWeight: 200,
                  marginBottom: 0,
                  marginLeft: 5,
                }}
              >
                {props.item.name.substring(0, 15)}..
              </h6>
              <h6
                style={{
                  textAlign: "left",
                  marginBottom: 0,
                  marginLeft: 5,
                }}
              >{`Rs:${props.item.sellPrice.toLocaleString()}`}</h6>
            </div>
          </div>
        </div>
      ) : (
        <h2>Loading</h2>
      )}
    </GridItem>
  );
};

export default OneItem;
