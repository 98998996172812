import React from "react";
import Input from "../FormItems/Input";

const Section_1 = ({ handleChange, values, onBlur, errors , loading}) => {
  // console.log("section",errors);
  return (
    <div>
      <Input
        name={"Id"}
        type={"text"}
        id={"id"}
        handleChange={handleChange}
        value={values.id}
        error={errors}
        onBlur={onBlur}
        loading={loading}
      />
      <Input
        name={"Name"}
        type={"text"}
        id={"name"}
        handleChange={handleChange}
        value={values.name}
        error={errors}
        onBlur={onBlur}
        loading={loading}
      />
      <Input
        name={"Description"}
        type={"text"}
        id={"description"}
        handleChange={handleChange}
        value={values.description}
        error={errors}
        onBlur={onBlur}
        loading={loading}
      />
    </div>
  );
};

export default Section_1;
