import React from "react";

const Log = ({ log }) => {

    var date = new Date(log.date?log.date:"2020-12-12");
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    var hr = date.getHours();
    var mn = date.getMinutes();
    var sn = date.getSeconds();
  
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

  return (
    <div className="row">
     
          <p style={{border:'solid',padding:10, }}>{`${year}-${month}-${dt} ${hr}:${mn}:${sn}`}</p>
          <p style={{border:'solid',padding:10, }}>{log.userEmail}</p>
          <p style={{border:'solid',padding:10, }}>{log.activity}</p>
       
    </div>
  );
};

export default Log;
