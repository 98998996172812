import jwtDecode from "jwt-decode";

export const SET_USER = "SET_USER";
export const CLOSE_MENU = "CLOSE_MENU";
export const OPEN_MENU = "OPEN_MENU";
export const VIEW_CHANGE = "VIEW_CHANGE";
export const REMOVE_USER = "REMOVE_USER";

export const ADMIN_CREATE = "ADMIN_CREATE";

export const AdminCreate = () => {
 // console.log("ADMIN_CREATE");
  return { type: ADMIN_CREATE };
};

export const addUser = (item, accountType) => {
 // console.log("ADD_ITEM_action", item);
  const obj = jwtDecode(item);
  const newItem = {
    _id: obj._id,
    fname: obj.fname,
    lname: obj.lname,
    email: obj.email,
    type: accountType,
    isAdmin: obj.isAdmin,
    number:obj.number
  };
console.log("obj",obj);
  return { type: SET_USER, user: newItem };
};

export const Close = () => {
 // console.log("_menuClose");
  return { type: CLOSE_MENU };
};

export const Open = (value) => {
 // console.log("_menuOpen", value);
  return { type: OPEN_MENU, value };
};

export const ViewChange = (value) => {
  var obj = { login: true, register: false, reset: false };
  if (value === "login") {
    obj = { login: true, register: false, reset: false };
  } else if (value === "register") {
    obj = { login: false, register: true, reset: false };
  } else if (value === "reset") {
    obj = { login: false, register: false, reset: true };
  }
  return { type: VIEW_CHANGE, obj };
};

export const RemoveUser = () => {
  return { type: REMOVE_USER };
};
