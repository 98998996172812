import React from "react";

const Totals = ({ order, Total }) => {
  return (
    <table>
      <tr>
        <th>Bill Total</th>
        <td>:{order.cartTotal.toLocaleString()}</td>
      </tr>
      <tr>
        <th>Delivery fee</th>
        <td>:{order.deliveryInfo.fee}</td>
      </tr>
      {order.deliveryInfo.paymentMethod !== "cashOnDelivery" && (
        <tr>
          <th>Tax fee</th>
          <td>:{order.tax}</td>
        </tr>
      )}
      <tr>
        <th>Final Total</th>
        <td
          style={{
            borderBottom: "double",
          }}
        >
          :{Total.toLocaleString()}
        </td>
      </tr>
    </table>
  );
};

export default Totals;
