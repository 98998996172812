import React from "react";
import Grid from "@material-ui/core/Grid";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useStyles } from "../style";

const Contact = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={3}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <h5 className={classes.Topic}>Contact </h5>
      <table style={{ color: "white", textAlign: "left" }}>
        <tr>
          <td>
            <PhoneIcon />
          </td>
          <td>+94 11 438 8400</td>
        </tr>
        <tr>
          <td>
            <WhatsAppIcon />
          </td>
          <td>+94 75 377 3377</td>
        </tr>
        <tr>
          <td>
            <MailOutlineIcon />
          </td>
          <td>Colombo Terminus Consulting (Pvt) Ltd</td>
        </tr>
        <tr>
          <td></td>
          <td>
            95H, Wakadawatta <br />
            Thumbowila Road
            <br />
            Bokundara <br />
            Piliyandala - 10300
          </td>
        </tr>
      </table>
    </Grid>
  );
};

export default Contact;
