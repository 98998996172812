import React, { useState } from "react";
import $ from "jquery";
import Button from "@material-ui/core/Button";

const Splash = ({ order }) => {
  const [state] = useState({
    first_name: order.userObj.fname,
    last_name: order.userObj.lname,
    email: order.email,
    contact_no: order.userObj.number,
    address: order.deliveryInfo.address,
    amount:
      parseInt(order.cartTotal) +
      parseInt(order.tax) +
      parseInt(order.deliveryInfo.fee),
  });

  const payNow = (e) => {
    const NodeRSA = require("node-rsa");
    const key = new NodeRSA(
      "-----BEGIN PUBLIC KEY-----\n" +
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDe67pW6hravTmPJMQQXCrNXjBq\n" +
        "/VYggcf/paX3jc62ry1Or7U0kc2M/8ZhEU/UtdbGByNxk0hnu/1pTRZOCSZ7DKGo\n" +
        "bxRdtm0+lbM45uwhG8e/D1scJXDIyQ08sC5TCTv8UmWw+WoSAlwFc4/IeEVGYMv8\n" +
        "MkeJoqYDq9+G6GOq0QIDAQAB\n" +
        "-----END PUBLIC KEY-----"
    );
    // const rand = min + Math.random() * (max - min);
    let price = state.amount;
    let text = order._id + "|" + price;
    const encrypted = key.encrypt(text, "base64");

    $(".something").append(
      '<form action="https://webxpay.com/index.php?route=checkout/billing" name="vote" id="vote" method="post" style="display:none;">' +
        '<input type="text" name="first_name" value="' +
        state.first_name +
        '" />' +
        '<input type="text" name="last_name" value="' +
        state.last_name +
        '" />' +
        '<input type="text" name="email" value="' +
        state.email +
        '" />' +
        '<input type="text" name="contact_number" value="' +
        state.contact_number +
        '" />' +
        '<input type="text" name="address_line_one" value="' +
        state.address_line_one +
        '" />' +
        '<input type="text" name="process_currency" value="' +
        "LKR" +
        '" />' +
        '<input type="text" name="secret_key" value="a3f03476-8978-477b-94eb-5fcb11f2a2fb" />' +
        '<input type="text" name="custom_fields" value="" />' +
        '<input type="text" name="payment" value="' +
        encrypted +
        '" />' +
        "</form>"
    );

    document.getElementById("vote").submit();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={payNow}
        style={{ float: "right" }}
      >
        Pay Now
      </Button>
      <div
        id="outer-container"
        className="container-fluid transiton-activatedg"
      >
        <div id="inside-container" className="container">
          <div className="row row_clr">
            <div className="something"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Splash;
