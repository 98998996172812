import React, { useRef, useState, useEffect } from "react";

const ImageUploader = (props) => {
  const [file, setFile] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickedHandler = (event) => {
    console.log("pickedHandler");
    let pickedFile;
    // let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      props.onInput(event.target.files[0]);
      setFile(pickedFile);
      setIsValid(true);
      // fileIsValid = true;
    } else {
      setIsValid(false);
      // fileIsValid = false;
    }
  };

  const pickImageHandler = () => {
    console.log("pickImageHandler");
    filePickerRef.current.click();
  };

  return (
    <div>
      <input
        id={"image"}
        ref={filePickerRef}
        style={{ display: "none" }}
        type="file"
        accept=".jpg,.png,.jpeg"
        onChange={pickedHandler}
      />
      <div className="image-upload__preview">
        {previewUrl && (
          <img
            src={previewUrl}
            alt="Preview"
            style={{ width: 150, height: 150 }}
          />
        )}
      </div>
      <button
        disabled={props.loading}
        type="button"
        onClick={pickImageHandler}
        style={{ margin: 10 }}
        className="btn btn-primary"
      >
        PICK IMAGE
      </button>

      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default ImageUploader;
