import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import AboutUs from "../Commen/AboutUsShort/AboutUsShort";
import Card from "@material-ui/core/Card";
import ItemsList from "./Components/ItemsList/ItemsList";
import { NAVI } from "data/data";
import { GetSubNaviBarItem } from "service/SubNaviBarRequest/SubNaviBarRequest";
import BestSellingItems from "../../CustomerView/Commen/BestSellingItems/BestSellingItems";
import { BestSellItems } from "service/HomePageRequestsa/HomePageRequestsa";

const SubNaviBarItemView = (props) => {
  const type = props.match.params.name;
  const keyWord = props.match.params.name;
  const tabNameObj = NAVI.filter((n) => n.keyWord === keyWord);
  const [subNaviBarItems, setSubNaviBarItems] = useState(null);
  const [page, setPage] = React.useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [bestSellItems, setBestSellItems] = useState(null);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setPage(1);
    const GetData = async () => {
      setSubNaviBarItems(null);
      setBestSellItems(null);
      const { data: bestSellItem } = await BestSellItems();
      const { data } = await GetSubNaviBarItem(type, page);
      if (data && bestSellItem) {
        setBestSellItems(bestSellItem);
        setSubNaviBarItems(data.content);
        setTotalItems(data.total);
      }
    };
    if (tabNameObj.length === 0) {
      props.history.push("/");
    } else {
      GetData();
    }
  }, [type, page, props.history,tabNameObj.length]);

  useEffect(() => {
    const GetData = async () => {
      setSubNaviBarItems(null);
      const { data } = await GetSubNaviBarItem(type, page);
      if (data) {
        setSubNaviBarItems(data.content);
        setTotalItems(data.total);
      }
    };
    if (tabNameObj.length === 0) {
      props.history.push("/");
    } else {
      GetData();
    }
  }, [page,props.history,tabNameObj.length, type]);

  return (
    <Grid
      sm={10}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      {tabNameObj.length !== 0 ? (
        <div>
          {/* <Grid style={{ marginLeft: 20, marginTop: 20 }}>
            <Alert variant="filled" severity="success">
              {tabNameObj.length <= 0 ? tabNameObj[0].name : null}
            </Alert>
          </Grid> */}
          <Grid style={{ marginLeft: 20, marginTop: 20, background: "white" }}>
            <ItemsList
              history={props.history}
              tabName={tabNameObj[0].name}
              items={subNaviBarItems}
              totalItems={totalItems}
              page={page}
              handleChange={handleChange}
            />
          </Grid>
          <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
            <BestSellingItems history={props.history} items={bestSellItems} />
          </Grid>
        </div>
      ) : null}
      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          marginBottom: 20,
          background: "while",
        }}
      >
        <Card style={{ padding: 30 }}>
          <AboutUs />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SubNaviBarItemView;
