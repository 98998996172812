import React from "react";

const ReadOnlyData = ({values}) => {
  return (
    <div>
      <div className={"row"}>
        <p style={{ border: "solid", borderWidth: 0.5, padding: 10 }}>
          Create Date :{values.date}
        </p>
        <p style={{ border: "solid", borderWidth: 0.5, padding: 10 }}>
          Rate :{values.rate}
        </p>
        <p style={{ border: "solid", borderWidth: 0.5, padding: 10 }}>
          User Id: {values.userId}
        </p>
        <p style={{ border: "solid", borderWidth: 0.5, padding: 10 }}>
          Sold count : {values.sold}
        </p>
      </div>
      <div className={"row"}>
        <img
          src={values.image}
          style={{ maxWidth: 350, marginRight: 20, margin: 10 }}
          alt="images"
        />
      </div>
    </div>
  );
};

export default ReadOnlyData;
