import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useDispatch } from "react-redux";
import { removeItem } from "store/actions/Cart";
import { Link } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5dee3",
    color: "black",
  },
  body: {
    fontSize: 14,
  },
  // cell: { border: "solid", borderWidth: 0.1, borderColor: "#d5dee3" },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export default function Tables({ cart, total }) {
  const dispatch = useDispatch();

  const FinalTotal = (price, qty) => {
    return price * qty;
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Product</StyledTableCell>
            <StyledTableCell align="center">Price</StyledTableCell>
            <StyledTableCell align="center">Quantity</StyledTableCell>
            <StyledTableCell align="center">Subtotal</StyledTableCell>
            <StyledTableCell align="center">Remove</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                component="th"
                scope="row"
                style={{
                  border: "solid",
                  borderWidth: 0.1,
                  borderColor: "#d5dee3",
                }}
              >
                <img src={row.image} width={40} style={{ marginRight: 20 }}  alt="images"/>
                {row.name}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  border: "solid",
                  borderWidth: 0.1,
                  borderColor: "#d5dee3",
                }}
              >
                {row.price.toLocaleString()}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  border: "solid",
                  borderWidth: 0.1,
                  borderColor: "#d5dee3",
                }}
              >
                {row.qty}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  border: "solid",
                  borderWidth: 0.1,
                  borderColor: "#d5dee3",
                }}
              >
                {FinalTotal(row.price, row.qty).toLocaleString()}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{
                  border: "solid",
                  borderWidth: 0.1,
                  borderColor: "#d5dee3",
                }}
              >
                <Link style={{ color: "red" }}>
                  <DeleteForeverIcon
                    onClick={() => {
                      // console.log("DeleteForeverIcon", row);
                      dispatch(removeItem(row));
                    }}
                  />
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow>
            <StyledTableCell component="th" scope="row"></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right">
              <p style={{ fontSize: 12, fontWeight: 400 }}>Final total:</p>
            </StyledTableCell>
            <StyledTableCell align="center">
              <p
                style={{
                  borderBottom: "double",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                RS: {total}
              </p>
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
