import React from "react";
import { brands } from "data/data";
import { CATEGORIES } from "data/data";
import Error from "./Error";

const Section_2 = ({ values, handleChange }) => {
  const getSubCategory = (mainCat) => {
    const mainCatObj =
      mainCat !== ""
        ? CATEGORIES.filter((m) => m.id === parseInt(mainCat))[0].sub
        : [{ id: 0, name: "" }];
    return mainCatObj;
  };

  return (
    <React.Fragment>
      <div className="form-group row">
        <div className="col-md-3 mb-3">
          <label for="mainCat">Promotions</label>
          <select
            id="mainCat"
            class="form-control"
            onChange={handleChange("promotion")}
            required
          >
            <option selected value="none">
              none
            </option>
            <option value="on_sale">On Sale</option>
            <option value="best_sale">Best Sale</option>
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label for="buyPrice" className="col-sm-2 col-form-label">
            BuyPrice
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              className="form-control"
              id="buyPrice"
              onChange={handleChange("buyPrice")}
              value={values.buyPrice}
              required
            />
            {values.buyPrice === "" || values.buyPrice <= 0 ? (
              <Error text={"buyPrice should be more than 0"} />
            ) : null}
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <label for="sellPrice" className="col-sm-2 col-form-label">
            SellPrice
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              className="form-control"
              id="sellPrice"
              onChange={handleChange("sellPrice")}
              value={values.sellPrice}
              required
            />
            {values.sellPrice === "" || values.sellPrice <= 0 ? (
              <Error text={"sellPrice should be more than 0"} />
            ) : null}
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <label for="brand">Brand</label>
          <select
            id="brand"
            class="form-control"
            onChange={handleChange("brand")}
            required
          >
            <option selected value={values.brand !== "" ? values.brand : ""}>
              {values.brand === ""
                ? "select brand"
                : brands.filter((m) => m.id === parseInt(values.brand))[0].name}
            </option>
            {brands.map((b) => (
              <option value={b.id}>{b.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-3 mb-3">
          <label for="mainCat">MainCat</label>
          <select
            id="mainCat"
            class="form-control"
            onChange={handleChange("mainCat")}
            required
          >
            <option
              selected
              value={values.mainCat !== "" ? values.mainCat : ""}
            >
              {values.mainCat === ""
                ? "Selectc Main Catergory"
                : CATEGORIES.filter((m) => m.id === parseInt(values.mainCat))[0]
                    .name}
            </option>
            {CATEGORIES.map((c) => (
              <option value={c.id}>{c.name}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label for="subCat">SubCat</label>
          <select
            id="subCat"
            class="form-control"
            disabled={values.mainCat === "" ? true : false}
            required
            onChange={handleChange("subCat")}
          >
            <option selected value={values.subCat !== "" ? values.subCat : ""}>
              {values.mainCat === ""
                ? "Selectc Main Catergory"
                : CATEGORIES.filter(
                    (m) => m.id === parseInt(values.mainCat)
                  )[0].sub.filter((e) => e.id === parseInt(values.subCat))[0]
                    .name}
            </option>
            {getSubCategory(values.mainCat).map((s) => (
              <option value={s.id}>{s.name}</option>
            ))}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label for="minQty" className="col-sm-2 col-form-label">
            MinQty
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              className="form-control"
              id="minQty"
              onChange={handleChange("minQty")}
              value={values.minQty}
              required
            />
            {values.minQty === "" || values.minQty <= 0 ? (
              <Error text={"minQty should be more than 0"} />
            ) : null}
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <label for="maxQty" className="col-sm-2 col-form-label">
            MaxQty
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              className="form-control"
              id="maxQty"
              onChange={handleChange("maxQty")}
              value={values.maxQty}
              required
            />
            {values.maxQty === "" || values.maxQty <= 0 ? (
              <Error text={"maxQty should be more than 0"} />
            ) : null}
          </div>
        </div>
        <div className="col-md-3 mb-3">
          <label for="stock" className="col-sm-2 col-form-label">
            stock
          </label>
          <div className="col-sm-10">
            <input
              type="number"
              className="form-control"
              id="stock"
              onChange={handleChange("stock")}
              value={values.stock}
              required
            />
            {values.stock === ""? (
              <Error text={"stock should be fill"} />
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Section_2;
