import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";
import { useSelector } from "react-redux";
import { COLOR } from "util/theme";
import { changePassword } from "service/UserHandle/UserHandle";
const useStyles = makeStyles({
  root: {
    paddingTop: 5,
    padding: 20,

    marginRight: 10,
    border: "solid",
    borderColor: COLOR.ashColor,
    marginBottom: 10,
  },
  text: { pagging: 0, margin: 0, overflow: "auto" },
  label: { pagging: 0, margin: 0, fontWeight: 600 },
});

export default function UserInfo(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.USER);
  const [textBox, setTextBox] = useState(false);
  const [loadig, setLoadig] = useState(false);

  const [values, setValues] = useState({
    password: "",
    new: "",
  });

  const [error, setError] = useState({
    password: "",
    new: "",
  });

  const hadleOnChange = (prop) => (event) => {
    const name = event.target.value;
    setValues({ ...values, [prop]: name });
    handleError(prop);
  };

  const handleError = (props) => {
    if (values[props] === "") {
      setError({ ...error, [props]: `this fiald requerd` });
    } else if (values[props] && values[props].length > 10) {
      setError({ ...error, [props]: `less than 10 ` });
    } else if (values[props] && values[props].length < 6) {
      setError({ ...error, [props]: `more than 6` });
    } else {
      setError({ ...error, [props]: null });
    }
  };

  const handleSave = async () => {
    setLoadig(true);
    try {
      const data = await changePassword(
        user.email,
        values.password,
        values.new
      );
      if (data) {
        console.log("ddad", data);
        setLoadig(false);
        alert("Done !");
        setTextBox(false);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        alert(ex.response.data);
        setLoadig(false);
        setValues({ ...values, password: "", new: "" });
        setTextBox(false);
      } else {
        alert("connection error");
        setLoadig(false);
        setValues({ ...values, password: "", new: "" });
        setTextBox(false);
      }
    }
  };

  return (
    <Card className={classes.root}>
      {user && (
        <div>
          <table>
            <tr>
              <td>
                <p className={classes.label}>Email:</p>
              </td>
              <td>
                <p className={classes.text}>{user.email}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p className={classes.label}>Name:</p>
              </td>
              <td>
                <p className={classes.text}>{`${user.fname} ${user.lname}`}</p>
              </td>
            </tr>
          </table>
          <p
            style={{ color: "blue"}}
            onClick={() => {
              setTextBox(!textBox);
            }}
          >
            Change password
          </p>
          {textBox && (
            <div>
              <div class="form-group">
                <input
                  type="password"
                  disabled={loadig && true}
                  class="form-control"
                  id="old"
                  placeholder="password"
                  onChange={hadleOnChange("password")}
                  value={values.password}
                />
                {error.password && <p style={{color:'red', fontSize:10}}>{error.password}</p>}
              </div>
              <div class="form-group">
                <input
                  disabled={loadig && true}
                  type="password"
                  class="form-control"
                  id="New password"
                  placeholder="New password"
                  onChange={hadleOnChange("new")}
                  value={values.new}
                />
                {error.new && <p style={{color:'red'}}>{error.new}</p>}
              </div>
              {!loadig ? (
                <button
                  type="button"
                  class="btn btn-primary"
                  disabled={
                    error.password === null && error.new === null ? false : true
                  }
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              ) : (
                <p>loading..</p>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  );
}
