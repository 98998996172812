import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ShowCountBox from "./components/ShowCountBox";
import TableView from "./components/TableView";
import { COLOR } from "util/theme";
import { useSelector } from "react-redux";

import { getDashboardProductDetails } from "service/DashBoardRequest/DashBoardRequest";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const All_summery = (props) => {
  const ordersDetails = useSelector((status) => status.orderReducer.COUNT);
  // const [ordersDetails, setOrdersDetails] = useState(false);
  const [productsDetails, setProductsDetails] = useState(false);

  useEffect(() => {
    const GetResult = async () => {
      // const orders = await getDashboardOrderDetails();
      // setOrdersDetails(orders);
      const products = await getDashboardProductDetails();
      setProductsDetails(products);
    };
    GetResult();
  }, []);

  const boxOnClick = () => {
    props.history.push("/admin/finish_order");
  };

  return (
    <div>
      {ordersDetails ? (
        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ marginTop: 5 }}
          >
            <ShowCountBox
              onClick={boxOnClick}
              color={COLOR.orderProcessing}
              name={"Procceing Orders"}
              count={ordersDetails.procesing}
            />
            <ShowCountBox
              onClick={boxOnClick}
              color={COLOR.orderConformed}
              name={"Conform Orders"}
              count={ordersDetails.conformed}
            />
            <ShowCountBox
              onClick={boxOnClick}
              color={COLOR.orderSend}
              name={"Send Orders"}
              count={ordersDetails.send}
            />
            <ShowCountBox
              onClick={boxOnClick}
              color={COLOR.orderDelivered}
              name={"Deliverd Orders"}
              count={ordersDetails.delivered}
            />
          </Grid>
          {productsDetails ? (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >
              <TableView
                color={"#d4f0d1"}
                name={"Fast Moving Products"}
                products={productsDetails.fastMove}
              />
              <TableView
                color={"#f0d2d1"}
                name={"None Moving Products"}
                products={productsDetails.slowMove}
              />
              <TableView
                color={"#f0d2d1"}
                name={"On Sale Products"}
                products={productsDetails.onSale}
              />
              <TableView
                color={"#f0d2d1"}
                name={"Best Sale Products"}
                products={productsDetails.bestSale}
              />
              <TableView
                color={"#f0d2d1"}
                name={"New Added Products"}
                products={productsDetails.newAdd}
              />
            </Grid>
          ) : (
            <BarLoader
              css={override}
              // width={100}
              color={"#123abc"}
              loading={!productsDetails}
            />
          )}
        </div>
      ) : (
        <div style={{ padding: 250 }}>
          <BarLoader
            css={override}
            // width={100}
            color={"#123abc"}
            loading={!ordersDetails}
          />
        </div>
      )}
    </div>
  );
};

export default All_summery;
