import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import NewAdditions from "../Commen/NewAdditios/NewAdditios";
import AboutUs from "../Commen/AboutUsShort/AboutUsShort";
import Card from "@material-ui/core/Card";
import { GetSeachResult } from "../../../service/SearchResult/SearchResult";
import { NewAddedItems } from "../../../service/HomePageRequestsa/HomePageRequestsa";
import SearchResultItems from "./SearchResultItems/SearchResultItems";

const SearchView = (props) => {
  const value = props.match.params.value;
  const [searchResult, setSearchResult] = useState(null);
  const [newAdd, setNewAdd] = useState(null);
  const [page, setPage] = React.useState(1);
  const [totalItems, setTotalItems] = useState(10);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const GetResult = async () => {
      setSearchResult(null);
      const { data: newItems } = await NewAddedItems();
      const SearchResult = await GetSeachResult(value, page);
      if (newItems) {
        setTotalItems(SearchResult.total);
        setSearchResult(SearchResult !== false ? SearchResult.data : 0);
        setNewAdd(newItems);
      }
    };
    GetResult();
    setPage(1);
  }, [value, page]);

  useEffect(() => {
    const GetResult = async () => {
      setSearchResult(null);
      const SearchResult = await GetSeachResult(value, page);
      if (SearchResult) {
        setSearchResult(SearchResult.data);
      }
    };
    GetResult();
  }, [page, value]);

  return (
    <Grid
      sm={10}
      xs={12}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid
        style={{ marginLeft: 20, marginTop: 20, background: "white" }}
      ></Grid>
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <SearchResultItems
          history={props.history}
          items={searchResult}
          page={page}
          handleChange={handleChange}
          totalItems={totalItems}
        />
      </Grid>
      {/* <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <Alert variant="filled" severity="success">
          This is a new added items — check it out!
        </Alert>
      </Grid> */}
      <Grid style={{ marginLeft: 20, marginTop: 20, background: "while" }}>
        <NewAdditions history={props.history} items={newAdd} />
      </Grid>
      <Grid
        style={{
          marginLeft: 20,
          marginTop: 20,
          marginBottom: 20,
          background: "while",
        }}
      >
        <Card style={{ padding: 30 }}>
          <AboutUs />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SearchView;
