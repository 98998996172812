import React from "react";
import GridContainer from "components/Grid/GridContainer";
import { Card } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import OneItem from "../../Commen/OneItem/OneItem";
import LoadingOneItem from "../../Commen/OneItem/LoadingOneItem";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const SearchResultItems = (props) => {
  // console.log("props", props);
  const loading = props.items === null ? true : false;

  return (
    <div>
      <Card>
        {props.items === 0 ? (
          <div style={{ padding: 10 }}>
            <h5>Sorry no result !</h5>
            <Button variant="contained" href="#contained-buttons" onClick={()=>{
              props.history.push("/");
            }}>
              Home
            </Button>
          </div>
        ) : loading ? (
          <Skeleton animation="wave" height={40} width="100%" />
        ) : (
          <div style={{ padding: 10 }}>
            <h5>Search Result</h5>
          </div>
        )}

        <Divider style={{ marginBottom: 10 }} />
        {props.items === 0 ? null : loading ? (
          <GridContainer>
            {[1, 2, 3, 4].map((c) => (
              <LoadingOneItem xs={6} sm={4} md={3} />
            ))}
          </GridContainer>
        ) : (
          <GridContainer style={{ paddingTop: 20 }}>
            {props.items.map((c) => (
              <OneItem xs={6} sm={4} md={3} history={props.history} item={c} />
            ))}
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginBottom: 15 }}
            >
              {props.totalItems >= 4 ? (
                <Pagination
                  count={props.totalItems}
                  variant="outlined"
                  color="secondary"
                  page={props.page}
                  onChange={props.handleChange}
                />
              ) : null}
            </Grid>
          </GridContainer>
        )}
      </Card>
    </div>
  );
};

export default SearchResultItems;
