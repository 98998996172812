import React from "react";
import Input from "../FormItems/Input";
import Select from "../FormItems/Select";
import { CATEGORIES } from "data/data";
import Error from "../FormItems/Error";

const Section_3 = ({ handleChange, values, onBlur, errors, loading }) => {
  const getSubCategory = (mainCat) => {
    const mainCatObj =
      mainCat !== ""
        ? CATEGORIES.filter((m) => m.id === parseInt(mainCat))[0].sub
        : [{ id: 0, name: "" }];
    return mainCatObj;
  };

  return (
    <div className="row" style={{ margin: 20 }}>
      <div className="col-md-3 mb-3">
        <Select
          handleChange={handleChange}
          options={CATEGORIES}
          name={"MainCat"}
          id={"mainCat"}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
      <div className="col-md-3 mb-3">
        <label for="brand" className="col-sm-2 col-form-label">
          SubCat
        </label>
        <select
          id="subCat"
          class="form-control"
          disabled={values.mainCat === "" || loading ? true : false}
          required
          onChange={handleChange("subCat")}
          onBlur={onBlur}
          loading={loading}
        >
          <option selected value={""}>
            Choose...
          </option>
          {getSubCategory(values.mainCat).map((s) => (
            <option value={s.id}>{s.name}</option>
          ))}
        </select>

        {errors && (
          <Error text={errors["subCat"] ? "Sub Category Required" : null} />
        )}
      </div>
      <div className="col-md-3 mb-3">
        <Input
          name={"MinQty"}
          type={"number"}
          id={"minQty"}
          handleChange={handleChange}
          value={values.minQty}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
      <div className="col-md-3 mb-3">
        <Input
          name={"MaxQty"}
          type={"number"}
          id={"maxQty"}
          handleChange={handleChange}
          value={values.maxQty}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Section_3;
