import React from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useStyles } from "./style";
import { Formik } from "formik";
import { registerValidationSchema } from "./Validations";
import Errors from "./Errors";
import { register } from "service/authService/authService";
import { COLOR } from "util/theme";
import { useDispatch } from 'react-redux';
import { AdminCreate } from 'store/actions/User';

export default function CreateArea() {
  const classes = useStyles();
const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{ email: "", password: "", name: "" }}
      validationSchema={registerValidationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        values.isAdmin = "admin";
        setSubmitting(true);
        try {
          
          await register(values);
          dispatch(AdminCreate());
          alert("account created");
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            alert(ex.response.data);
          } else {
            alert("connection error");
          }
        }
        setSubmitting(false);
        resetForm();
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <form className={classes.root} noValidate onSubmit={handleSubmit}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <TextField
              label="e-mail"
              type="email"
              variant="outlined"
              size="small"
              onChange={handleChange("email")}
              value={values.email}
              helperText={errors.email ? <Errors msg={errors.email} /> : null}
            />
            <TextField
              label="name"
              type="text"
              variant="outlined"
              size="small"
              onChange={handleChange("name")}
              value={values.name}
              helperText={errors.name ? <Errors msg={errors.name} /> : null}
            />
            <TextField
              type="password"
              onChange={handleChange("password")}
              value={values.password}
              variant="outlined"
              size="small"
              helperText={
                errors.password ? <Errors msg={errors.password} /> : null
              }
            />
            {isSubmitting ? (
              <p>Loading..</p>
            ) : (
              <Button
                disabled={
                  errors.email || errors.password || errors.name ? true : false
                }
                variant="contained"
                color="primary"
                size="small"
                type={"submit"}
                style={{ background: COLOR.formButton, color: "white" }}
              >
                Create
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
}
