import React from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../style";


const TopCategoris = (props) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={3}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <h5 className={classes.Topic}>Top Categoris</h5>
      <Grid container direction="row" justify="center" alignItems="center">
        <a className={classes.link} href={"/layout/main_categories/1/1"}>
          Daily Essentials
        </a>
        <a className={classes.link} href={"/layout/main_categories/2/1"}>
          Grocery Items
        </a>
        <a className={classes.link} href={"/layout/main_categories/3/1"}>
          Gift / Cake
        </a>
        <a className={classes.link} href={"/layout/main_categories/4/1"}>
          Health
        </a>
      </Grid>
    </Grid>
  );
};

export default TopCategoris;
