import {
  ADD_ITEM,
  INITE,
  REMOVE,
  DELIVERY_INF,
  REMOVE_CART,
  LAST_ORDER,
} from "./../actions/Cart";

const initialState = {
  CART: [],
  TOTAL: 0,
  USER_DETALIS: null,
  DELIVERY_INFOMATION: null,
  L_ORDER: null,
};

const getTotal = (cart) => {
  var tot = 0;
  cart.map((c) => {
    var value = c.qty * c.price;
    tot += value;
    return null;
  });
  return tot;
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      const localCart = JSON.parse(sessionStorage.getItem("cart"))
        ? JSON.parse(sessionStorage.getItem("cart"))
        : [];
      const newList = [...localCart, action.item];
      sessionStorage.setItem("cart", JSON.stringify(newList));
      const total = getTotal(newList);
      return { ...state, CART: newList, TOTAL: total };

    case INITE:
      const Cart = JSON.parse(sessionStorage.getItem("cart"))
        ? JSON.parse(sessionStorage.getItem("cart"))
        : [];
      const initiTotal = Cart.length > 0 ? getTotal(Cart) : 0;
      return { ...state, CART: Cart, TOTAL: initiTotal };

    case REMOVE:
      // console.log("action.item", action.item._id);
      const cart = [...state.CART];

      var removeIndex = cart.indexOf(action.item);
      // console.log("removeIndex", removeIndex);
      cart.splice(removeIndex, 1);

      sessionStorage.setItem("cart", JSON.stringify(cart));
      const total_after_remove = getTotal(cart);
      return { ...state, CART: cart, TOTAL: total_after_remove };

    case DELIVERY_INF:
      return { ...state, DELIVERY_INFOMATION: action.info };

    case REMOVE_CART:
      sessionStorage.removeItem("cart");
      return { ...state, CART: [], TOTAL: 0, DELIVERY_INFOMATION: null };

    case LAST_ORDER:
      return { ...state, L_ORDER: action.order };
    default:
      return state;
  }
};

export default cartReducer;

// var apps = [{id:34,name:'My App',another:'thing'},{id:37,name:'My New App',another:'things'}];

// // get index of object with id:37
// var removeIndex = apps.map(function(item) { return item.id; }).indexOf(37);

// // remove object
// apps.splice(removeIndex, 1);
