import React from "react";
import Input from "../FormItems/Input";
import Select from "../FormItems/Select";
import { brands } from "data/data";

const Section_2 = ({ handleChange, values, onBlur, errors, loading }) => {
  return (
    <div className="row" style={{ margin: 20 }}>
      <div className="col-md-3 mb-3">
        <Input
          name={"Stock"}
          type={"number"}
          id={"stock"}
          handleChange={handleChange}
          value={values.stock}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
      <div className="col-md-3 mb-3">
        <Input
          name={"BuyPrice"}
          type={"number"}
          id={"buyPrice"}
          handleChange={handleChange}
          value={values.buyPrice}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
      <div className="col-md-3 mb-3">
        <Input
          name={"SellPrice"}
          type={"number"}
          id={"sellPrice"}
          handleChange={handleChange}
          value={values.sellPrice}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
      <div className="col-md-3 mb-3">
        <Select
          handleChange={handleChange}
          options={brands}
          name={"Brand"}
          id={"brand"}
          error={errors}
          onBlur={onBlur}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Section_2;
