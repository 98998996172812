import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ItemView from "./Components/ItemView/ItemView";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { BrandWiseItem } from "service/MainCategoryRequestsa/MainCategoryRequestsa";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Products = ({ state }) => {
  const key = useSelector((state) => state.orderReducer.key);

  const [orders, setOrders] = useState(false);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = React.useState(1);
  const [totalitems, setTotalitems] = useState(0);
  const [count, setCount] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const GetData = async () => {
      const data = await BrandWiseItem(state, 1);

      if (data) {
        setLoading(false);
        setOrders(data.content ? data.content : data);
        setPage(data.page);
        setTotalitems(data.total);
      }
    };
    GetData();
  }, [key, state]);

  useEffect(() => {
    setLoading(true);
    setOrders(false);
    const GetData = async () => {
      const data = await BrandWiseItem(state, page);

      if (data) {
        setOrders(data.content ? data.content : data);
        setCount(data.all);
      }
      setLoading(false);
    };
    GetData();
  }, [page, state]);

  return (
    <div>
      {loading && <h1 style={{ textAlign: "center", margin: 200 }}>loading</h1>}
      {loading ? (
        <div style={{ padding: 250 }}>
          <BarLoader
            css={override}
            // width={100}
            color={"#123abc"}
            loading={loading}
          />
        </div>
      ) : (
        <ItemView
          content={orders}
          page={page}
          handleChange={handlePageChange}
          totalitems={totalitems}
          count={count}
          state={state}
        />
      )}
    </div>
  );
};

export default Products;
