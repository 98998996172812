import React from "react";
import Product from "./Product";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";

const ItemView = ({ content, handleChange, totalitems, page }) => {
  return (
    <div style={{ margin: 10 }}>
      {typeof content === "object" ? (
        <p>
          {content.length > 0 ? (
            <div>
              {content.map((r) => (
                <Product product={r} />
              ))}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ marginBottom: 15 }}
              >
                {totalitems >= 2 ? (
                  <Pagination
                    count={parseInt(totalitems)}
                    variant="outlined"
                    color="secondary"
                    page={parseInt(page)}
                    onChange={handleChange}
                  />
                ) : null}
              </Grid>
            </div>
          ) : (
            <p>No data</p>
          )}
        </p>
      ) : (
        <p>{content}</p>
      )}
    </div>
  );
};
export default ItemView;
