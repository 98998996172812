import HTTP from "service/HttpService/HttpService";

export const relatedItems = async (id) => {
  const res = await HTTP.get(`${HTTP.endPoint}related_items/${id}`);
  return res.data ? res.data : false;
};

export const cartViewRelatedItems = async () => {
  const res = await HTTP.get(`${HTTP.endPoint}cart_view_reated_items`);
  return res.data ? res.data : false;
};
