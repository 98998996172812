import React, { useState, useEffect } from "react";
import SelectionAreas from "./Components/SelectionArea";
import ItemView from "./Components/ItemView";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import { GetSeachResultAdmin } from "service/SearchResult/SearchResult";

export default function Product_report() {
  const [values, setValues] = React.useState({
    name: "",
  });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalitems, setTotalitems] = useState(0);

  // const getData = async (Page) => {
  //   setLoading(true);

  //   const res = await GetSeachResultAdmin(values.name, Page);

  //   if (res) {
  //     setContent(res.content);
  //     setPage(res.page);
  //     setTotalitems(res.total);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    const GetResult = async () => {
      setLoading(true);

      const res = await GetSeachResultAdmin(values.name, page);

      if (res) {
        setContent(res.content);
        setPage(res.page);
        setTotalitems(res.total);
      }
      setLoading(false);
    };
    if (page > 0) {
      GetResult();
    }
  }, [page, values.name]);

  const handleChange = (prop) => (event) => {
    const name = event.target.value;
    setValues({ ...values, [prop]: name });
  };

  const handleSearchButton = async () => {
    setLoading(true);

    const res = await GetSeachResultAdmin(values.name, 1);

    if (res) {
      setContent(res.content);
      setPage(res.page);
      setTotalitems(res.total);
    }
    setLoading(false);
  };

  return (
    <div>
      <SelectionAreas
        handleChange={handleChange}
        values={values}
        handleSearchButton={handleSearchButton}
      />

      {loading ? (
        <div style={{ padding: 250 }}>
          <BarLoader css={override} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <ItemView
          content={content}
          page={page}
          handleChange={handlePageChange}
          totalitems={totalitems}
        />
      )}
    </div>
  );
}
