import { SEARCH_ITEMS, SEARCH_CLEAR } from "./../actions/Items";

const initialState = {
  SEARCH_RESULT: false,
};

const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ITEMS:
      return { ...state, SEARCH_RESULT: action.items };

    case SEARCH_CLEAR:
      return { ...state, SEARCH_RESULT: null };
    default:
      return state;
  }
};

export default itemsReducer;
