import React from "react";
import Grid from "@material-ui/core/Grid";
import TopCategoris from "./Components/TopCategoris";
import Suggestions from "./Components/Suggestions";
import About from "./Components/About";
import Contact from "./Components/Contact";

const Footer = () => {
  return (
    <Grid style={{ background: "red", marginTop: 50 }}>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <TopCategoris />
        <Suggestions />
        <About />
        <Contact />
      </Grid>
      <div className="footer-copyright text-center py-3">
        &copy; {new Date().getFullYear()} Copyright:
        <a href="/" style={{ color: "white" }}>
          speedystoremart powered by Colombo Terminus Consulting
        </a>
      </div>
    </Grid>
  );
};

export default Footer;
