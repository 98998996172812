import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const SelectionAreas = ({ handleChange, values, handleSearchButton }) => {
  return (
    <div style={{ marginTop: 20 }}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="flex-start"
      >
        <div class="form-group col-md-6">
          <input
            value={values.name}
            type="text"
            class="form-control"
            id="name"
            placeholder="Product name"
            onChange={handleChange("name")}
          />
        </div>

        <Button
          disabled={values.name ? false : true}
          variant="contained"
          color="primary"
          onClick={() => {
            handleSearchButton();
          }}
        >
          Search
        </Button>
      </Grid>
    </div>
  );
};
export default SelectionAreas;
