import { COLOR } from 'util/theme';


const typographyStyle = {
  price: {
    color: COLOR.green,
    fontSize: 15,
    fontWeight: 900,
    marginLeft: 10,
    marginBottom: 8,
  },
  name: {
    color: "black",
    fontSize: 15,
    fontWeight: 100,
    marginLeft: 10,
    marginBottom: 8,
    marginTop: 5,
  },

  media: {
    height: 160,
  },

  card: {
    width: "90%",
    marginBottom: 50,
    border: "solid",
    borderWidth: 0.5,
    borderColor: "#D7CFCF ",
  },
  marginLeft: {
    marginLeft: "auto !important",
  },

  buttonText:{
    color:COLOR.buttonTextColor
  }
};

export default typographyStyle;
