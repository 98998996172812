import React from "react";
import Grid from "@material-ui/core/Grid";
import Table from "./Components/Table";
import { useSelector } from "react-redux";
import ForMe from "./Components/ForMe";

export default function DeliveryInfo(props) {
  const cart = useSelector((state) => state.cartReducer.CART);
  const total = useSelector((state) => state.cartReducer.TOTAL);

  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid xs={12} sm={12}>
          <Table cart={cart} total={total} />
        </Grid>
      </Grid>
      <Grid>
        <ForMe next={props.next} />
        <div style={{ background: "white" }}></div>
      </Grid>
    </div>
  );
}
