import http from "service/HttpService/HttpService";
import jwtDecode from "jwt-decode";


const tokenKey = "token";

http.setJwt(getJwt()); //calling the function in http service

export async function login(email, password) {
  const { data: jwt } = await http.post(`${http.endPoint}auth/`, {
    email,
    password,
  });

  localStorage.setItem(tokenKey, jwt);
  return jwt;
}
export function logout() {
  localStorage.removeItem(tokenKey);
}
export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}
export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey); // getting token obj from localStorage
    return jwtDecode(jwt); // decoding the token
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export const register = async (user) => {
  const res = await http.post(`${http.endPoint}users`, {
    email: user.email,
    password: user.password,
    fname: user.fname,
    lname: user.lname,
    isAdmin:user.isAdmin,
    number: user.phoneNumber
  });
 
  return res;
};

export default {
  login,
  logout,
  loginWithJwt,
  getCurrentUser,
  getJwt,
};
