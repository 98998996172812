import React, { useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Switch } from "react-router-dom";
import AppBar from "./component/AppBar/AppBar";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import Catogeries from "./component/SideMenu/SideMenu";
import All_summery from "views/DashBoardView/Manager/DashBoard/all_summery/All_summery";
import Finish_order from "views/DashBoardView/Manager/Orders/finish_order/Finish_order";
import Orders_Report from "views/DashBoardView/Manager/Orders/order_report/Order_report";
import AddProducts from "views/DashBoardView/Manager/Product/Add_products/AddProduct";
import Update_product from "views/DashBoardView/Manager/Product/update_product/Update_product";
import Product_View from "views/DashBoardView/Manager/Product/Product_View_Cat_Wise/Product_View";
import Product_report from "views/DashBoardView/Manager/Product/Product_Report/ProductReport";
import Product_Brand_wise from "views/DashBoardView/Manager/Product/Product_View_Brand_view/Product_Brand_wise";
import CreateAdmin from "views/DashBoardView/Manager/UserManagment/CreateUser/CreateAdmin";
import { addUser } from "store/actions/User";
import { getJwt } from "service/authService/authService";
import PrivateRoute from "util/PrivateRoute";
import PrivateRouteSuper from "util/PrivateRouteSuper";
import PendingProduct from "views/DashBoardView/Manager/Product/Pending_product/PendingProduct";
import ViewLog from "views/DashBoardView/Manager/UserManagment/ViewLog/ViewLog";
import OrderPrint from "views/DashBoardView/Manager/PrintPdf/OrderPrint";

const MainLayout = (props) => {
  const matches = useMediaQuery("(min-width:790px)");
  const dispatch = useDispatch();
  useEffect(() => {
    const jwt = getJwt();
    if (jwt) {
      dispatch(addUser(jwt, "A"));
    }
  }, [dispatch]);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12}>
          <AppBar history={props.history} />
        </Grid>

        {matches && (
          <Grid item xs={12} sm={2}>
            <Catogeries history={props.history} />
          </Grid>
        )}
        <Grid item xs={12} sm={10}>
          <Switch>
            <PrivateRoute path="/admin/all_summery" component={All_summery} />
            <PrivateRoute path="/admin/finish_order" component={Finish_order} />
            <PrivateRoute
              path="/admin/order_report"
              component={Orders_Report}
            />
            <PrivateRoute path="/admin/add_products" component={AddProducts} />
            <PrivateRoute
              path="/admin/update_product"
              component={Update_product}
            />
            <PrivateRoute
              path="/admin/products_category_wise"
              component={Product_View}
            />
            <PrivateRoute
              path="/admin/products_brand_wise"
              component={Product_Brand_wise}
            />
            <PrivateRoute
              path="/admin/products_report"
              component={Product_report}
            />
            <PrivateRoute path="/admin/print_order" component={OrderPrint} />
            <PrivateRouteSuper
              path="/admin/create_admin"
              component={CreateAdmin}
            />
            <PrivateRouteSuper
              path="/admin/pending_product"
              component={PendingProduct}
            />
            <PrivateRouteSuper
              path="/admin/view_user_log"
              component={ViewLog}
            />
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainLayout;
